import React, { Component } from 'react';
import { Container, Row, Col,Card, CardBody, CardImg,} from 'reactstrap';


class PrivacyPolicy extends Component {
  render() {
  document.title = 'Privacy Policy';
    return (
      <div>

      <div className="section-shaped section-lg">
      <div className="noise-break short">
        <div className="container col-lg-12">
          <div className="text-white">
            <h1 className="title"> Privacy Policy </h1>
          </div>
          <div className="title text-white">
            <i className="text-white fa fa-calendar" aria-hidden="true"></i>
            <span className="effective-date"> Effective Date: &nbsp; December 31st, 2018 </span>
          </div>
        </div>
      </div>
      <div className="article-breadcrumbs-container">
        <div className="container">
          <ul className="breadcrumbs">
            <li>
              <a href="http://support.uclid.com/index.htm">
                <i className="fa fa-home" aria-hidden="true"></i>
              </a>
            </li>
            <li title="Uclid Help Desk">
              <a href="http://support.uclid.com/index.htm">Uclid Help Desk</a>
            </li>
            <li title="Privacy Policy">
              Privacy Policy
            </li>

          </ul>
        </div>
      </div>
      <div className="container ">
        <div className="wrapper">
          <div  className="main">
            <div className="article-body">
            <div class="category-page">
                <div class="loc-banner-container"></div>
                <div class="category-container">
                    <div class="category-trees policies">
                        <div class="section-tree-with-article">
                            <ul>
                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="uclid-privacy-policy" title="UCLID PRIVACY POLICY">Introduction</h2>
                                    <ul>
                                        UCLID is an Education network and an opportunity building platform. All registered users can experience the full functionality of UCLID network/App either as a student, teacher, parent or admin of an organization. We aim to make a space where authenticity of portfolios is maintained for credible exchange of information as well as a place where teaching, learning and earning is well enabled among all users.<br/> At UCLID we promise to keep you happy by placing your data safely. Hassle free user experience is our oath. <br/> This privacy policy of UCLID helps you understand what data we collect, why we collect it, and what we do with it.<br/> This page is used to inform UCLID users regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.<br/> If you choose to use our Services, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving Services. We will not use or share your information with anyone except as described in this Privacy Policy.<br/> The terms used in this Privacy Policy have the same meanings as in our Terms of service, which is accessible at UCLID unless otherwise defined in this Privacy Policy.<br/>
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="information-collection-and-use" title="Information Collection and Use">Information Collection and Use</h2>
                                    <ul>
                                    For a better experience, while using our Services, we require you to provide us with certain personally identifiable information, including but not limited to Name, age, DOB, Phone number, Email id. The information that we request will be retained by us and used as described in this privacy policy.
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="log-data" title="Log Data">Log Data</h2>
                                    <ul>
                                        We want to inform you that whenever you use our Service, in a case of an error in the service, we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="service-providers" title="Service Providers">Service Providers</h2>
                                    <ul>
                                        We may employ third-party companies and individuals due to the following reasons:
                                        <li>To facilitate our Service;</li>
                                        <li>To provide the Service on our behalf;</li>
                                        <li>To perform Service-related services; or</li>
                                        <li>To assist us in analysing how our Service is used.</li>
                                        We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="security" title="Security">Security</h2>
                                    <ul>
                                        We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="information-you-provide-us" title="Information you provide us">How We Use the Information you provide us</h2>
                                    <ul>
                                        <li>We use the information you provided to send you marketing communications, communicate with you about our upcoming Services and let you know about our policies and terms.</li>
                                        <li>We also use your information to personalize your experiences as part of our provision of the Services.</li>
                                        <li>We use your information for conducting data and system analytics, including research to improve the Services. For this purpose, UCLID shall, to the extent possible, use de-identified or aggregated data.</li>
                                        <li>We use your information to help verify accounts and activity, and to promote safety and security on our network by investigating suspicious activity or violations of our terms or policies. We also use your information to respond to you when you contact us.</li>
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="links-to-other-sites" title="Links to Other Sites">Links to Other Sites</h2>
                                    <ul>
                                        This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="device-and-location" title="Device and Location">Device and Location</h2>
                                    <ul>
                                        When you visit or leave our site/app (including our plugins or cookies or similar technology on the sites of others), we receive the URL of both the site you came from and the one you go to next. We also get information about your IP address, proxy server, operating system, web browser and add-ons, device identifier and features, and/or ISP or your mobile carrier. If you use our site/app from a mobile device, that device will send us data about your location. Most devices allow you to prevent location data from being sent to us and we honour your preference.
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="legal-disclosures" title="Legal Disclosures">Legal Disclosures</h2>
                                    <ul>
                                        It is possible that we will need to disclose information about you when required by law, court summons, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to:
                                        <li>Investigate, prevent, or take action regarding suspected or actual illegal activities or to assist government enforcement agencies;</li>
                                        <li>Effectuate our agreements with you,</li>
                                        <li>Investigate and defend ourselves against any third-party claims or allegations,</li>
                                        <li>Protect the security or integrity of our Service; or</li>
                                        <li>Exercise or protect the rights and safety of UCLID, our Members, personnel, or others. We will attempt to notify users about legal demands for their personal data when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague or lack proper authority, but we do not guarantee to challenge every demand.</li>
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="data-retention" title="Data Retention">Data Retention</h2>
                                    <ul>
                                        We retain Personal Information that you provide us as long as we consider it potentially useful in contacting you about our services, or as needed to comply with our legal obligations, resolve disputes and enforce our agreements, and then we securely delete the information. Personal Information that we process for any purpose or purposes shall not be kept for longer than is necessary for such purpose or purposes. We may retain your Personal Information where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another user. <br/>
                                        We provide many options about the collection, use and sharing of your data, from deleting or correcting data you include in your Profile and controlling the visibility of your posts to advertising opt-outs and communication controls. We also offer access to the personal data we have about you.
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="account-closure" title="Account Closure">Account Closure</h2>
                                    <ul>
                                        If you choose to close your UCLID account, your personal data will stop being visible to others on our site and services generally within 24 hours. We delete closed account information within 30 days of account closure.<br/>
                                        When you delete your account, we delete your profile information and any other content you provide in your profile such as your name, username, password, email address, and profile photos and depending on the category of user you are (i.e., teacher, admin, parent or student) information collected through the mobile permissions you’ve granted. Information that you have shared with others, others have shared about you, or content other users may have copied and stored, is not part of your account and may not be deleted when you delete your account on UCLID. Even if your account is deleted, messages sent between teachers and parents are retained to assist schools with various recordkeeping or compliance obligations. This includes any content uploaded, including photos and videos between parents and teachers.<br/> Please note that we may have to retain some information provided by you after your account is closed, to comply with legal obligations, to protect the safety and security of our community or our Service, or to prevent abuse of our Terms.
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="compliance-and-cooperation" title="Compliance and cooperation">Compliance and cooperation with regulatory authorities</h2>
                                    <ul>
                                        We regularly review our privacy policy and are compliant with statutory mandates governing privacy and data protection. We strictly adhere to the regulatory framework ‘Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011.’ When we receive formal written complaints, we will contact the person who made the complaint to follow up. We will work with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of personal data that we cannot resolve with our users directly.
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="children-privacy" title="Children’s Privacy">Children’s Privacy</h2>
                                    <ul>
                                        UCLID does not directly address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take necessary actions.
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="changes-to-this-privacy-policy" title="Changes to This Privacy Policy ">Changes to This Privacy Policy</h2>
                                    <ul>
                                        We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
                                    </ul>
                                </li>

                                <li class="section"><a class="zd_anchor" name="UCLID PRIVACY POLICY"></a>
                                    <h2 id="contact-us" title="Contact Us">Contact Us</h2>
                                    <ul>
                                        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at:<br />
                                         Email- <a href="mailto: support@uclid.com"> <b> support@uclid.com </b> </a> <br/>

                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            </div>

          </div>
        </div>
      </div>
    </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
