import React, { Component } from 'react';
import { Container, Row, Col,Card, CardBody, CardImg,} from 'reactstrap';
import {Helmet} from "react-helmet";

class Home extends Component {
  render() {
    return (
      <div>
        <Helmet>
           <meta charSet="utf-8" />
           <title>Home</title>
           <meta name="description" content="A learning management system, focused communication network & progress management platform, Uclid is the right combination of essential tools you require for attaining excellence in your organization."/>
           <link rel="canonical" href="https://www.uclid.com/" />
        </Helmet>
        <main>
          <div className="position-relative">
              {/* shape Hero */}
            <section className="section section-shaped pb-7">
              <div className="shape shape-style-1 shape-default">
              </div>
              <div className="container-fluid landing d-flex pb-5">
                <div className="col pt-md">
                  <div className="row ">
                    <div className="col-lg-6 ">
                      <h1 className="display-3 landing-title">Learning Management System for every classroom!</h1>
                      <p className="lead">Configurable for Schools, Colleges & Training Institutions.</p>

                      <div className="row landing-blocks pt-4">
                        <div className="card col-lg-4 col-md-6">
                          <div className="card-body assess">
                            <h6 className="text-roles text-uppercase">Digitalize Academics</h6>
                          </div>
                        </div>

                        <div className="card col-lg-4 col-md-6">
                          <div className="card-body communicate">
                            <h6 className="text-roles text-uppercase">Communicate with peers</h6>
                          </div>
                        </div>

                        <div className="card col-lg-4 col-md-6">
                          <div className="card-body digitalize ">
                            <h6 className="text-roles text-uppercase">Assess the progress</h6>
                          </div>
                        </div>
                      </div>


                    </div>
                    <div className="col-lg-6 mt--8">
                      <img src={require('../../images/svg/1.svg')} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div id="#info-blocks" className="info-blocks pt-lg-0 mt--80">
            <div className="floatingblock pt-5 mb-5">
              <h2 className="display-3 text-center">One profile for Multiple Academic Needs</h2>
              <div className="spacer-small"></div>
              <h4 className="display-5 text-center">UCLID connects Organization Leaders, Teachers, Students and <br/> Parents to create an amazing educational culture.</h4>
              <div className="floatingblockcolumns">
                <div className="intro-cards">
                  <div className="intro-cards-container">
                    <div className="intro-card-container">
                      <div className="card align-center">
                        <a href="#" onclick="navigate('1')" className="tile" title="Admin">
                          <div className="icon icon-shape bg-admin rounded-circle mb-4">
                            <img src={require('../../images/icons/admin.svg')} />
                          </div>
                          <h6 className="text-roles text-uppercase">Admin</h6>
                          <p className="card-description"> Build your virtual organization on UCLID to manage and communicate within.</p>
                        </a>
                      </div>
                    </div>
                    <div className="intro-card-container">
                      <div className="card align-center">
                        <a href="#" onclick="navigate('2')" className="tile" title="Teachers">
                          <div className="icon icon-shape bg-teacher text-white rounded-circle shadow mb-4">
                            <img src={require('../../images/icons/teacher.svg')} />
                          </div>
                          <h6 className="text-roles text-uppercase">Teachers</h6>
                          <p className="card-description">For Classroom and beyond, we got you covered!</p>
                        </a>
                      </div>
                    </div>
                    <div className="intro-card-container">
                      <div className="card align-center">
                        <a href="#" onclick="navigate('3')" className="tile" title="Students">
                          <div className="icon icon-shape bg-student text-white rounded-circle shadow mb-4">
                            <img src={require('../../images/icons/student.svg')} />
                          </div>
                          <h6 className="text-roles text-uppercase">Students</h6>
                          <p className="card-description">Connect, Learn & Communicate without barriers!</p>
                        </a>
                      </div>
                    </div>
                    <div className="intro-card-container">
                      <div className="card align-center">
                        <a href="#" onclick="navigate('4')" className="tile" title="Parents">
                          <div className="icon icon-shape bg-parent text-white rounded-circle shadow mb-4">
                            <img src={require('../../images/icons/parent.svg')} />
                          </div>
                          <h6 className="text-roles text-uppercase">Parents</h6>
                          <p className="card-description">Experience your child's classroom real time with our APP</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <section className="pt-3 pb-5">
          <div className="container">
            <div className="row row-grid align-items-center">
              <div className="col-lg-5 col-md-8 order-lg-1 order-md-2   mt-5 free-to-use">
                <img src= {require('../../images/svg/mobile-login.svg')} className="free-svg img-fluid floating" />
              </div>
              <div className="col-lg-7 col-md-12 order-lg-2 order-md-1 order-sm-1 pl-md">
                <div className="pr-md-5">
                  <h3 className="text-primary mb-4">UCLID for Education</h3>
                  <p>Teaching & learning are two processes that needs to be cherished. With Uclid we wish to bring the best of technology to make this process even more beautiful. The modules and features available on Uclid contribute to improve day to day activities and performance of organization, teachers & students while making parents an integrated part of this journey.</p>
                  <p>A learning management system(LMS), focused communication network & progress management platform, Uclid is the right combination of essential tools you require for attaining excellence in your organization.</p>
                  <h5 class="description">For an organization of any size, any nature,  anywhere with one profile on UCLID,  a lot is POSSIBLE!</h5>
                </div>
              </div>
            </div>
          </div>
        </section>


        <div className="darksection-bubbles pt-5 col-lg-12">
          <div className="">
          <h1 className="display-3 text-center text-white"> A Seamless Experience, Everywhere! </h1>
          <h3 className="lead text-center text-white pb-3">We have native apps for iOS and Android.</h3>
          <div className="appbuttons" align="center">
            <div className="storebuttons"><a href="https://play.google.com/store/apps/details?id=com.uclid.mobile&hl=en" target="blank"><img src={require('../../images/play-store.png')}/></a></div>
            <div className="storebuttons"><a href="https://itunes.apple.com/in/app/uclid/id1452459085#?platform=iphone" target="blank"><img src={require('../../images/app-store.png')}/></a></div>
          </div>
          <div className="wireframes">
            <img src={require('../../images/wireframes.png')}className="devicesimage"/>
          </div>
        </div>
        </div>

        <section className="advantages advantages--header">
          <div className="pt-5 row-title">Nothing like UCLID!</div>
          <div className="section container">
            <div className="advantage">
            <figure class="advantage__icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px">
                <path fill="#91DDF6" d="M25 9.9v11.69l8.83-8.83a5 5 0 1 1 1.41 1.41L26.41 23H38.1a5 5 0 1 1 0 2H26.41l8.83 8.83a5 5 0 1 1-1.41 1.41L25 26.41V38.1a5 5 0 1 1-2 0V26.41l-8.83 8.83a5 5 0 1 1-1.41-1.41L21.59 25H9.9a5 5 0 1 1 0-2h11.69l-8.83-8.83a5 5 0 1 1 1.41-1.41L23 21.59V9.9a5 5 0 1 1 2 0z"></path>
                <circle fill="#45B2E8" cx="24" cy="24" r="6"></circle>
              </svg>
            </figure>
              <h4 className="bold">Unified Solution</h4>

              <p className="description">
                With the right blend of LMS, Communication and Progress management tools under single APP, Uclid is ‘one’ Unified solution that every organization needs.
              </p>
            </div>
            <div className="advantage">
            <figure className="advantage__icon"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
              <g fill="none" fill-rule="evenodd">
                <g>
                  <rect width="44" height="5" x="2" y="40" fill="#FDD760" rx="1"></rect>
                  <path fill="#E5A03E" d="M6 38.5c0-.83.67-1.5 1.5-1.5h33c.83 0 1.5.67 1.5 1.5V40H6v-1.5z"></path>
                  <path fill="#FDD760" d="M38 21a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1v1h-6v-1a1 1 0 0 0 1-1V22a1 1 0 0 0-1-1v-1h6v1zm-11 0a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1v1h-6v-1a1 1 0 0 0 1-1V22a1 1 0 0 0-1-1v-1h6v1zm-11 0a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1v1h-6v-1a1 1 0 0 0 1-1V22a1 1 0 0 0-1-1v-1h6v1z"></path>
                  <path fill="#E5A03E" d="M6 15h36v1.5c0 .83-.67 1.5-1.5 1.5h-33A1.5 1.5 0 0 1 6 16.5V15z"></path>
                  <path fill="#FDD760" d="M22.22 3.9a4.5 4.5 0 0 1 3.56 0l19.54 9.76C46.8 14.4 46.67 15 45 15H3c-1.66 0-1.8-.6-.32-1.34l19.54-9.77z"></path>
                </g>
              </g>
              </svg>
              </figure>
              <h4 className="bold">Virtual Organization</h4>
              <p className="description">
                Replicate your Organization structure on UCLID and make it your virtual organization, You will be surprised to see the wonders that follow!
                </p>
            </div>

            <div className="advantage">
              <figure className="advantage__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                  <path fill="#7795f8" d="M27.488,31.637a11.973,11.973,0,0,1,10.679-7.393v1.815a1.569,1.569,0,0,0,2.481,1.274l6.164-4.42a1.566,1.566,0,0,0,0-2.548l-6.164-4.42a1.566,1.566,0,0,0-2.481,1.274v1.825A17.165,17.165,0,0,0,22.153,31.119a8.413,8.413,0,0,1,5.334.518ZM24,44.28a5.333,5.333,0,1,0-5.333-5.333A5.333,5.333,0,0,0,24,44.28Z"></path>
                  <path fill="#555abf" d="M.533,21.638a1.571,1.571,0,0,0,.656,1.274l6.164,4.42a1.58,1.58,0,0,0,.915.292,1.561,1.561,0,0,0,1.561-1.566V24.244a11.973,11.973,0,0,1,9.2,4.789c.12-.057.239-.115.359-.168a20.263,20.263,0,0,1,2.442-4.535,17.074,17.074,0,0,0-12-5.268V17.218a1.556,1.556,0,0,0-1.561-1.561,1.58,1.58,0,0,0-.92.287l-6.159,4.42A1.561,1.561,0,0,0,.533,21.638ZM24,21.777v0a20.823,20.823,0,0,1,2.6-2.213V13.046h1.82a1.566,1.566,0,0,0,1.274-2.481L25.274,4.4a1.566,1.566,0,0,0-2.548,0L18.3,10.566a1.566,1.566,0,0,0,1.274,2.481h1.82v6.494A20.512,20.512,0,0,1,24,21.777Z"></path>
                </svg>
              </figure>
              <h4 className="bold">One profile multiple roles</h4>
              <p className="description">
                We know how difficult it is for you & your mobile to download multiple Apps for different roles. You can be an Organization leader, parent, teacher & student all at once on Uclid. Try it now to see how!
              </p>
            </div>

          </div>
        </section>

        <section className=" advantages advantages--header">
          <div className="container">
            <div className="advantage">
              <figure className="advantage__icon"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                <g fill="none" transform="translate(-5.5 -11.736)">
                  <path fill="#FFD1F1" d="M18.5,54 C17.6715729,54 17,53.3284271 17,52.5 L17,30.5 C17,29.6715729 17.6715729,29 18.5,29 L37,29 L37,54 L18.5,54 Z M25,33 C25,32.4477153 24.5522847,32 24,32 L21,32 C20.4477153,32 20,32.4477153 20,33 L20,36 C20,36.5522847 20.4477153,37 21,37 L24,37 C24.5522847,37 25,36.5522847 25,36 L25,33 Z M25,41 C25,40.4477153 24.5522847,40 24,40 L21,40 C20.4477153,40 20,40.4477153 20,41 L20,44 C20,44.5522847 20.4477153,45 21,45 L24,45 C24.5522847,45 25,44.5522847 25,44 L25,41 Z M32,33 C32,32.4477153 31.5522847,32 31,32 L28,32 C27.4477153,32 27,32.4477153 27,33 L27,36 C27,36.5522847 27.4477153,37 28,37 L31,37 C31.5522847,37 32,36.5522847 32,36 L32,33 Z"></path>
                  <path fill="#D782D9" d="M51.5,54 L47,54 L47,46 C47,45.4477153 46.5522847,45 46,45 L42,45 C41.4477153,45 41,45.4477153 41,46 L41,54 L35,54 L35,17.5 C35,16.6715729 35.6715729,16 36.5,16 L51.5,16 C52.3284271,16 53,16.6715729 53,17.5 L53,52.5 C53,53.3284271 52.3284271,54 51.5,54 Z M43,29 C43,28.4477153 42.5522847,28 42,28 L39,28 C38.4477153,28 38,28.4477153 38,29 L38,32 C38,32.5522847 38.4477153,33 39,33 L42,33 C42.5522847,33 43,32.5522847 43,32 L43,29 Z M43,37 C43,36.4477153 42.5522847,36 42,36 L39,36 C38.4477153,36 38,36.4477153 38,37 L38,40 C38,40.5522847 38.4477153,41 39,41 L42,41 C42.5522847,41 43,40.5522847 43,40 L43,37 Z M50,37 C50,36.4477153 49.5522847,36 49,36 L46,36 C45.4477153,36 45,36.4477153 45,37 L45,40 C45,40.5522847 45.4477153,41 46,41 L49,41 C49.5522847,41 50,40.5522847 50,40 L50,37 Z"></path>
                  <path fill="#FFD1F1" d="M49,33 L46,33 C45.4477153,33 45,32.5522847 45,32 L45,29 C45,28.4477153 45.4477153,28 46,28 L49,28 C49.5522847,28 50,28.4477153 50,29 L50,32 C50,32.5522847 49.5522847,33 49,33 Z M49,25 L46,25 C45.4477153,25 45,24.5522847 45,24 L45,21 C45,20.4477153 45.4477153,20 46,20 L49,20 C49.5522847,20 50,20.4477153 50,21 L50,24 C50,24.5522847 49.5522847,25 49,25 Z M42,25 L39,25 C38.4477153,25 38,24.5522847 38,24 L38,21 C38,20.4477153 38.4477153,20 39,20 L42,20 C42.5522847,20 43,20.4477153 43,21 L43,24 C43,24.5522847 42.5522847,25 42,25 Z M40,52 L48,52 C48.5522847,52 49,52.4477153 49,53 L49,54 L39,54 L39,53 C39,52.4477153 39.4477153,52 40,52 Z"></path>
                </g>
              </svg>
              </figure>

              <h4 className="bold">Multi Organization Connect</h4>

              <p className="description">
                With one profile on Uclid, manage and track activities of various organizations you are part of, its easy to switch. Thank us later!
              </p>
            </div>

            <div className="advantage">
            <figure class="advantage__icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                <g fill="none" fill-rule="evenodd">
                  <g>
                    <path fill="#8898AA" fill-rule="nonzero" d="M32.54 12.43l-1.05-1.06c3.93-5.84.96-5.75-6.76-6.62L23.3 3.32c11.6-1.11 13.51.8 16.85 4.14 1.43 1.43 1.89 3.84 2.84 4.8.73.72 2.29 1.3 3.35 2.37.48.47 0 .96 0 .96l-2.94 2.94s-.49.48-.97 0c-.95-.95-.8-1.4-.97-1.9-1.5-4.65-5.81-1.1-5.81-1.1l-.99-.98-14.18 14.19.24.24c.65.65.65 1.7 0 2.33L7.52 44.52c-.64.64-1.7.64-2.34 0l-2.6-2.6a1.65 1.65 0 0 1 0-2.34l13.2-13.2a1.66 1.66 0 0 1 2.34 0l.24.24 14.18-14.19z"></path>
                    <path fill="#32325D" d="M11.62 11.09l-1.06-3.2L4.53 4 2.41 6.13l3.9 6.02 3.18 1.06 15.76 15.76-.24.24c-.65.65-.65 1.7 0 2.35L37.96 44.5c.65.65 1.7.65 2.35 0l2.6-2.6c.66-.66.66-1.7.01-2.36L29.97 26.6a1.66 1.66 0 0 0-2.35 0l-.24.24L11.62 11.1z"></path>
                  </g>
                </g>
              </svg>
            </figure>
              <h4 className="bold">Highly Configurable</h4>

              <p className="description">
                Uclid is made for Global use for organizations of various natures, we have given our best to fit all those needs and will strive to add many more configurations every day forward.
              </p>
            </div>


            <div className="advantage">
              <figure className="advantage__icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
              <g fill="none" fill-rule="evenodd">
                <g>
                  <path fill="#E5A03E" d="M6 16a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3v21a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V16zm0 3v4.17h40V19H6z" transform="rotate(8 26 26.5)"></path>
                  <rect width="40" height="27" x="1" y="6" fill="#FDD760" rx="3"></rect>
                  <path fill="#E5A03E" d="M30 26a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2h-4a1 1 0 0 1-1-1zm-8 0a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2h-4a1 1 0 0 1-1-1zm-8 0a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2h-4a1 1 0 0 1-1-1zm-8 0a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2H7a1 1 0 0 1-1-1zM5 11a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-6z"></path>
                </g>
              </g>
            </svg>
            </figure>

              <h4 className="bold">Cost Efficient</h4>

              <p className="description">
                Let us know when you come across a product of this superior quality at such economical value, we will wait!
              </p>
            </div>



          </div>
        </section>

        <section className="section advantages advantages--header">
          <div className="container">
            <div className="advantage">
              <figure className="advantage__icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                <g fill="none" transform="translate(-5.5 -11.736)">
                  <path fill='#24b47e' d="M44,29V24.24a7.51,7.51,0,0,0-15,0V29H24V24.5a12.5,12.5,0,0,1,25,0V29Z" ></path>
                  <path fill='#3ecf8e' d="M33,39a4,4,0,0,0,2,3.46V46a2,2,0,0,0,2,2v6H23a4,4,0,0,1-4-4V33a4,4,0,0,1,4-4H37v6A4,4,0,0,0,33,39Z" ></path>
                  <path d="M50,54H37V48a2,2,0,0,0,2-2V42.46A4,4,0,0,0,37,35V29H50a4,4,0,0,1,4,4V50A4,4,0,0,1,50,54Z" fill='#24b47e'></path>
                </g>
              </svg>
              </figure>
              <h4 className="bold">Privacy First</h4>

              <p className="description">
                Uclid believes that <b>‘your information is yours!’</b> On Uclid, organizations & Users are in total control of their data.  Privacy & Security are our fundamental  foundations and will always remain so.
                Know more from our <a href="javascript:void(0)" onClick={() => { this.props.history.push('/center');}}> Privacy Center </a>
              </p>
            </div>
            <div className="advantage">
            <figure class="advantage__icon">
              <svg viewBox="0 0 45 45" width="45" height="45" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd"><g transform="translate(15)">
                  <path d="M13.442 3.524L14.712 0h1.125l1.27 3.524c.244.04.485.087.723.142L20.352.898l1.04.43-.175 3.742c.208.13.412.267.61.41l3.392-1.594.795.795-1.593 3.391c.142.2.279.403.409.611l3.741-.174.43 1.039-2.768 2.522c.056.238.103.478.143.722l3.524 1.27v1.126l-3.524 1.27a11.19 11.19 0 0 1-.167.824l2.793 2.42-.43 1.04-3.687-.264c-.165.269-.34.53-.526.784l1.655 3.307-.795.795-3.307-1.655a11.27 11.27 0 0 1-.784.526l.263 3.686-1.039.431-2.42-2.793c-.304.074-.614.135-.927.184l-1.168 3.507h-1.125l-1.167-3.507c-.314-.049-.623-.11-.927-.184l-2.42 2.793-1.04-.43.264-3.687a11.27 11.27 0 0 1-.785-.526l-3.306 1.655-.795-.795 1.655-3.307a11.27 11.27 0 0 1-.526-.784l-3.687.263-.43-1.039 2.793-2.42a11.194 11.194 0 0 1-.184-.927L.65 15.187v-1.124l3.507-1.168c.043-.279.096-.554.16-.825l-2.77-2.522.431-1.04 3.742.175c.13-.208.267-.412.41-.61L4.535 4.68l.795-.795 3.391 1.593c.199-.142.403-.279.611-.409L9.158 1.33l1.04-.431 2.521 2.768s.48-.103.723-.142z" fill="#FFA27B">
                  </path>
                  <circle fill="#FFF" cx="15.275" cy="14.625" r="2.25"></circle>
                </g>
                <g transform="translate(0 11)">
                  <path d="M9.622 5.674L6.617 3.619 5.238 4.777 6.74 8.094c-.208.232-.41.471-.602.717l-3.527-.903-.9 1.559 2.543 2.6a13.4 13.4 0 0 0-.321.884l-3.62.357L0 15.081l3.283 1.576c-.005.155-.005.78 0 .936L0 19.17l.313 1.773 3.62.357c.097.3.204.594.321.883l-2.543 2.601.9 1.559 3.527-.903c.193.246.394.485.602.717l-1.502 3.318 1.38 1.157 3.004-2.055c.265.166.536.323.812.47l-.276 3.628 1.691.616 2.12-2.957c.305.064.613.119.925.162l.98 3.505h1.8l.982-3.505c.312-.043.62-.098.924-.162l2.12 2.957 1.692-.616-.276-3.628c.276-.147.547-.304.812-.47l3.005 2.055 1.378-1.157-1.502-3.318c.21-.232.41-.471.603-.717l3.527.903.9-1.559-2.543-2.6a13.4 13.4 0 0 0 .321-.884l3.62-.357.313-1.773-3.283-1.576c.005-.155.005-.78 0-.936l3.283-1.576-.313-1.773-3.62-.357a13.4 13.4 0 0 0-.321-.883l2.543-2.601-.9-1.559-3.527.903a13.572 13.572 0 0 0-.603-.717l1.502-3.317-1.378-1.158-3.005 2.055a13.486 13.486 0 0 0-.812-.47l.276-3.628L21.701.96l-2.12 2.957a13.452 13.452 0 0 0-.925-.162L17.675.25h-1.8l-.981 3.505c-.312.043-.62.098-.925.162L11.85.96l-1.691.616.276 3.628c-.276.147-.547.304-.812.47z" fill="#FFCCA5">
                  </path>
                <circle fill="#FFF" cx="16.775" cy="17.125" r="3.375"></circle>
                </g>
                </g>
              </svg>
            </figure>
              <h4 className="bold">Easy to use</h4>

              <p className="description">
                  Uclid is a minimum typing application which aids teachers and admins in organizing quick and easy classroom activities. You will hardly reach our customer support once you get going!
                </p>
            </div>
            <div className="advantage">
              <figure className="advantage__icon"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
              <g id="Artboard-2" fill="none" fill-rule="evenodd">
                <path id="2" fill="#CDD1F7" d="M38,18 L36,18 L36,12 C36,11.4477153 35.5522847,11 35,11 L5,11 C4.44771525,11 4,11.4477153 4,12 L4,30 C4,30.5522847 4.44771525,31 5,31 L10,31 L10,33 L5,33 C3.34314575,33 2,31.6568542 2,30 L2,8 C2,6.34314575 3.34314575,5 5,5 L35,5 C36.6568542,5 38,6.34314575 38,8 L38,18 Z"></path>
                <path id="1" fill="#A78BEA" d="M45,36.1116394 C44.7824728,35.9427579 44.533119,35.8072194 44.2570199,35.7151864 L43,35.2961797 L43,20 C43,19.4477153 42.5522847,19 42,19 L12,19 C11.4477153,19 11,19.4477153 11,20 L11,38 C11,38.5522847 11.4477153,39 12,39 L31.296077,39 L31.9627437,41 L12,41 C10.3431458,41 9,39.6568542 9,38 L9,16 C9,14.3431458 10.3431458,13 12,13 L42,13 C43.6568542,13 45,14.3431458 45,16 L45,36.1116394 Z M40.3373802,40.3972465 L45.8044591,45.8643254 C45.9997213,46.0595875 45.9997213,46.37617 45.8044591,46.5714322 L44.5713551,47.8045362 C44.376093,47.9997983 44.0595105,47.9997983 43.8642484,47.8045362 L38.3971694,42.3374572 L36.5238962,45.709349 C36.3897896,45.9507409 36.085388,46.0377128 35.8439961,45.9036062 C35.7346427,45.8428543 35.6520347,45.7433178 35.6124759,45.6246414 L31.9226594,34.555192 C31.8353356,34.2932204 31.9769156,34.0100604 32.2388872,33.9227365 C32.3415206,33.8885254 32.4524816,33.8885254 32.555115,33.9227365 L43.6245644,37.612553 C43.886536,37.6998768 44.028116,37.9830368 43.9407921,38.2450085 C43.9012334,38.3636848 43.8186254,38.4632213 43.709272,38.5239732 L40.3373802,40.3972465 Z"></path>
              </g>
              </svg>
              </figure>
              <h4 className="bold">Ever ready support</h4>

              <p className="description">
                We love when you use our App and will be dedicated to support throughout your journey with us. Make use of our ‘Ever Ready’ quality support for seamless experience on Uclid. Reach us to know more about dedicated account Manager.
              </p>
            </div>
          </div>
        </section>


        {/* <section className="section">
      <div className="container">
        <div className="row row-grid align-items-center">
          <div className="col-md-5 order-md-2 mt-5">
            <img src= {require('../../images/svg/multiple-roles.svg')} className="floating" />
          </div>
          <div className="col-md-7 order-md-1 pl-md">
            <div className="">
              <h3 className="text-primary mb-4">Virtual Organization</h3>
              <p>Replicate your organization structure on Uclid, assign administrators at various levels to manage day to day activities relating to student development. Uclid helps to organize all student activities from Admissions to certifications, sports & skills, grading and assessments and much more.</p>
              <ul className="list-unstyled mt-5">
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-dark mr-3">
                        <i className="fa fa-sign-in" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <h6 className="mb-0">Carefully crafted components</h6>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-danger mr-3">
                        <i className="fa fa-area-chart" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <h6 className="mb-0">Amazing page examples</h6>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-info mr-3">
                        <i className="fa fa-shield" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <h6 className="mb-0">Super friendly support team</h6>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </div>

        <div className="row row-grid align-items-center pb-5">
          <div className="col-md-5 order-md-1 mt-5 pr-md-5">
            <img src= {require('../../images/svg/virtual-organization.svg')} className="img-fluid floating" />
          </div>
          <div className="col-md-7 order-md-2 mt-5 ">
            <div className="">
              <h3 className="text-primary mb-4">One Profile Multiple Roles</h3>
              <p>UCLID allows users to have multiple roles with one profile. A user can be an administrator, teacher, student and parent at one or many organizations at a time. Our state of the art user experience would ease up any complex scenarios and helps in bringing the best of your role.</p>
              <ul className="list-unstyled mt-5">
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-dark mr-3">
                        <i className="fa fa-sign-in" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <h6 className="mb-0">Carefully crafted components</h6>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-danger mr-3">
                        <i className="fa fa-area-chart" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <h6 className="mb-0">Amazing page examples</h6>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-info mr-3">
                        <i className="fa fa-shield" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <h6 className="mb-0">Super friendly support team</h6>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </section> */}

        <section className="pb-5">
          <div className="container">
            <div className="row row-grid align-items-center">
              <div className="col-lg-5 col-md-8 order-lg-2 order-md-2 mt-5 free-to-use">
                <img src= {require('../../images/mobile.svg')} className="free-svg img-fluid floating" />
              </div>
              <div className="col-lg-7 col-md-12 order-lg-1 order-md-1 order-sm-1">
                <div className="pr-md-5">
                  <h3 className="text-primary">An LMS made for you, with love!</h3>
                  <ul className="list-unstyled mt-5">
                    <li className="py-3">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="badge badge-circle badge-primary mr-3">
                            <i className="fa fa-rocket" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div>
                          <h6 className="mb-0 points-description">One APP</h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-3">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="badge badge-circle badge-darker mr-3">
                            <i className="fa fa-sign-in" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div>
                          <h6 className="mb-0 points-description">Two Minutes to SignUp</h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-3">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="badge badge-circle badge-success mr-3">
                            <i className="fa fa-shield" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div>
                          <h6 className="mb-0 points-description">One Day to Setup</h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-3">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="badge badge-circle badge-warning mr-3">
                            <i className="fa fa-area-chart" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div>
                          <h6 className="mb-0 points-description">100% Progress</h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-3">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="badge badge-circle badge-info mr-3">
                            <i className="fa fa-bolt" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div>
                          <h6 className="mb-0 points-description">Zero Payment*</h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    );
  }
}

export default Home;
