import React, { Component } from 'react';
// import './App.css';
// import logo from 'assets/images/uclid.svg';

class Footer extends Component {
  render() {
    return (
      <div>

      <footer className="footer">
        <div className="container">

          <div className=" row align-items-center justify-content-md-between">
            <div className="col-md-3 col-sm-6 col-xs-6 site-nav">
              <div className="column">
                <h4>About</h4>
                <div className="splitColumn">
                  <ul >
                    <li><a href="javascript:void(0)" onClick={() => { this.props.history.push('/careers');}}>Careers</a></li>
                    <li><a href="javascript:void(0)" onClick={() => { this.props.history.push('/blog');}}>In the News</a></li>
                    <li><a href="http://support.uclid.com/faq.html">FAQ</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 site-nav">
              <div className="column">
                <h4>Resources</h4>
                <ul>
                  <li><a href="javascript:void(0)" onClick={() => { this.props.history.push('/blog');}}>Blog</a></li>
                  <li><a href="javascript:void(0)" onClick={() => { this.props.history.push('/privacy');}}>Privacy Policy</a></li>
                  <li><a href="javascript:void(0)" onClick={() => { this.props.history.push('/terms');}}>Terms of Service</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 site-nav">
              <div className="column">
                <h4>Support</h4>
                <ul>
                  <li><a href="http://support.uclid.com/index.html">Help & Support</a></li>
                  <li><a href="javascript:void(0)" onClick={() => { this.props.history.push('/center');}}>Privacy Center</a></li>
                  <li><a href="javascript:void(0)" onClick={() => { this.props.history.push('/contact');}}>Contact</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6 site-nav">
              <div className="column">
                <h4>Keep in Touch</h4>
                <ul>
                  <li><a href="https://www.instagram.com/uclid_lms/">Instagram</a></li>
                  <li><a href="https://www.facebook.com/uclidlms/">Facebook</a></li>
                  <li><a href="https://twitter.com/uclid_lms">Twitter</a></li>
                </ul>
              </div>
            </div>
          </div>
          <hr style={{borderTop:'0.0625em solid rgba(0, 0, 0, .05)'}}/>
          <div className="container">
            <div className="row align-items-center justify-content-xl-between">
              <div className="col-xl-6">
                <div className="copyright text-center text-xl-left text-muted">
                  &copy; 2019 <a href="https://www.uclid.com" className="font-weight-bold ml-1">UCLID</a>.
                </div>
              </div>
              <div className="col-xl-6">
                <ul className="nav nav-footer justify-content-center justify-content-xl-end">
                  <li className="nav-item">
                    <a href="https://www.facebook.com/uclidlms/" className="nav-link" target="_blank">Facebook</a>
                  </li>
                  <li className="nav-item">
                    <a href="/" className="nav-link" target="_blank">Linkedin</a>
                  </li>
                  <li className="nav-item">
                    <a href="https://www.instagram.com/uclid_lms/" className="nav-link" target="_blank">Instagram</a>
                  </li>
                  <li className="nav-item">
                    <a href="https://twitter.com/uclid_lms" className="nav-link" target="_blank">Twitter</a>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>

      </footer>

      </div>
    );
  }
}

export default Footer;
