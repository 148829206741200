import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class Blog extends Component {
  render() {
    return (
      <div>
        <Helmet>
           <meta charSet="utf-8" />
           <title>Blog</title>
           <meta name="description" content="In the advanced age of technology, edtech facilitates educational organizations to provide students with quick access to information"/>
           <meta name="keywords" content="challenges in use of edtech by organizations, virtual organizational portfolios, easy data movement, increase efficiency in assessment, cost efficient implementation, communicative networking solution, need for a unified network, technology in academics"/>
           <link rel="canonical" href="https://www.uclid.com/about" />
        </Helmet>

        <section id="blog" className="blog pt-7 pb-7">
          <div className="container ">
            <div className="news-room pt-5 pb-7">
              <h2 className="text-secondary blog-title pb-5">NEWSROOM</h2>
              <a href="https://inc42.com/buzz/uclid-hyderabad-edtech-funding/" className="featured-article">
                <div className="featured-article-image">
                  <img className="team" src={require('../../images/blog.jpg')} alt="Inc 42"/>
                </div>
                <div className="featured-article-content">
                  <h4 className="featured-article-type">Exclusive INC 42 :</h4>
                  <h3 className="common-IntroText featured-article-title">
                    Hyderabad Based Edtech Startup UCLID Raises Seed Funding From US-Based Investors
                  </h3>
                  <h5 className="common-BodyText featured-article-subtitle"> Oct 12, 2017</h5>
                </div>
              </a>
            </div>

              {/* <!-- Section title  -->*/}
              <div className="section-title sm-ac ">
                  <h2><span className="thin" align="center">Popular </span><b>Blog</b><span className="thin"> Posts</span></h2>
    <p className="light thin ">
  {/* Lorem ipsum Dolor sit Mollitia harum ea ut eaque velit. */}
  </p>
              </div>
              {/* <!-- Section title end -->*/}
              <div className="row">
                  <div className="col-12 col-lg-4 blog-cards">
                      <a href="javascript:void(0)" onClick={() => { this.props.history.push('/technology-for-teachers');}}>
                          <div className="blog-card">
                              <div className="blog-frame">
                                  <div className="more-tooltip bg-color-1"></div>
                                  <img src={require('../../images/blog/teachersday.jpg')}  alt="blog image"/></div>
                              <div className="blog-description">
                                  <p className="bold dark">Did we thank our teachers yet?</p>
                                  <p className="light thin">Teaching is the most responsible profession one can take up. </p>
                              </div>
                          </div>
                      </a>
                  </div>
                  <div className="col-12 col-lg-4 blog-cards">
                      {/* <!-- Blog card --> */}
                      <a href="javascript:void(0)" onClick={() => { this.props.history.push('/technology');}}>
                          <div className="blog-card">
                              <div className="blog-frame">
                                  <div className="more-tooltip bg-color-1"></div>
                                  <img src={require('../../images/blog/technology.jpg')} alt="blog image"/></div>
                              <div className="blog-description">
                                  <p className="bold dark">Technology in Academics</p>
                                  <p className="light thin">Challenges in use of Edtech by Organizations</p>
                              </div>
                          </div>
                      </a>
                  </div>
                  {/* <div className="col-12 col-lg-4 blog-cards">

                      <a href="javascript:void(0)" onClick={() => { this.props.history.push('/technology');}}>
                          <div className="blog-card">
                              <div className="blog-frame">
                                  <div className="more-tooltip bg-color-1"></div>
                                  <img src={require('../../images/blog/blog-3.jpg')}  alt="blog image"/></div>
                              <div className="blog-description">
                                  <p className="bold dark">How dreams turn into reality</p>
                                  <p className="light thin">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem non...</p>
                              </div>
                          </div>
                      </a>
                  </div> */}
              </div>
            </div>
        </section>
      </div>
    );
  }
}

export default Blog;
