import React, { Component } from 'react';
import { Button, Container, Row, Col, Form, FormFeedback, FormGroup, Label, Input,Modal,ModalHeader} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {Helmet} from "react-helmet";

class Contact extends Component {
  state= {
    isResponseModalOpen: false,
    formSubmission: null,
  }

  toggleResponseModal = () => {
    this.setState((prevState) => {
      return {
        isResponseModalOpen: !prevState.isResponseModalOpen
      }
    })
  }

  autoCloseResponseModal = () => {
    setTimeout(() => {
      this.setState({
        isResponseModalOpen: false
      })
    }, 3000)
  }

  onSubmit = (values, { setSubmitting, resetForm }) => {
      setSubmitting(false);

      setTimeout(() => {
        resetForm({name:"",email:"",number:"",organization:"",message:""});
      }, 2000);

      let data = {
       'fullName': values && values.name,
       'email': values && values.email,
       'phoneNumber': values && values.number,
       'organization': values && values.organization,
       'message': values && values.message
      }

      console.log(data)

      this.setState({
        formSubmission: 'ongoing',
      });
      axios.post('http://13.233.38.145/uclid-contact/add', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((res) => {
          console.log(res)
          if(res.status === 200){
            this.setState({
              formSubmission: 'successful',
              isResponseModalOpen:true,
            })
            this.autoCloseResponseModal()
          } else {
            this.setState({
              formSubmission: 'failed',
              isResponseModalOpen:true,
            })
          }
        })
        .catch((e) =>{
          console.log('errer', e)
          this.setState({
            formSubmission: 'failed',
            isResponseModalOpen:true,
          })
        })
      };

  schema = () => {
    return Yup.object().shape({
        name: Yup.string()
            .required("Full Name is required"),
        email: Yup.string()
            .required("Email id is required")
            .email(" Email id is invalid"),
        number: Yup.string()
            .required("Mobile Number is required")
            .matches(/(?=.*\d)/, 'mobile number must contain: numbers only')
            .min(10, 'Mobile number must be 10 digits')
            .max(10, 'Mobile number must be 10 digits'),
        organization: Yup.string()
            .required("Organization Name is required"),
        message: Yup.string()
            .required("Please enter your message"),
    })
  };


  render() {
    return (
      <div className="">
        <Helmet>
           <meta charSet="utf-8" />
           <title>Contact</title>
           <meta name="description" content="Get in touch"/>
           <link rel="canonical" href="https://www.uclid.com/careers" />
        </Helmet>


        <div id="contact" className="container-fluid">

        <Col lg="12" md="12" className="get-in-touch pt-7">
          <img className="contact-discussion-image col-lg-5 " style={{position:'absolute', width:'40%',top: '12rem',left: '0px'}} src={require('../../images/svg/discussion.svg')} />
          <img className="contact-pot-image" style={{position:'absolute', width:'10%',top: '12rem',right: '0px'}} src={require('../../images/svg/plant.svg')}/>
          <div className="container">
            <Col className="contact-wrap-wrap align-items-end"  lg={{ size: 7, offset: 5 }} md={{ size: 7, offset: 5 }} sm={{ size: 12 }}>
              <section id="content">
                <div className="row ">
                  <div className="col-xs-12 text-center">
                  </div>
                </div>
                <div className="row-title pb-3">Get in Touch</div>
                <h5 className="contact-header-description">Fill out this form and our team will contact you shortly.</h5>

                <Formik
                    enableReinitialize={true}
                    initialValues={{name:"",email:"",number:"",organization:"",message:""}}
                    onSubmit={this.onSubmit}
                    validationSchema={this.schema}
                >
                    {props => {
                        const {
                            handleSubmit,
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                        } = props;
                        return (
                            <Form onSubmit={handleSubmit} noValidate>
                                <FormGroup>
                                    <Label htmlFor={'name'}>Full Name</Label>
                                    <Input type="text"
                                            name={'name'}
                                            id={'name'}
                                            invalid={touched['name'] && !!errors['name']}
                                            required
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values['name']} />
                                    <FormFeedback>{errors['name']}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor={'email'}>Email</Label>
                                    <Input type="text"
                                            name={'email'}
                                            id={'email'}
                                            invalid={touched['email'] && !!errors['email']}
                                            required
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values['email']} />
                                    <FormFeedback>{errors['email']}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor={'number'}>Mobile Number</Label>
                                    <Input type="text"
                                            name={'number'}
                                            id={'number'}
                                            invalid={touched['number'] && !!errors['number']}
                                            required
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values['number']} />
                                    <FormFeedback>{errors['number']}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor={'organization'}>Organization Name</Label>
                                    <Input type="text"
                                            name={'organization'}
                                            id={'organization'}
                                            invalid={touched['organization'] && !!errors['organization']}
                                            required
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values['organization']} />
                                    <FormFeedback>{errors['organization']}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor={'message'}>Message</Label>
                                    <Input type="textarea"
                                            name={'message'}
                                            id={'message'}
                                            invalid={touched['message'] && !!errors['message']}
                                            required
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values['message']} />
                                    <FormFeedback>{errors['message']}</FormFeedback>
                                </FormGroup>

                                <div>
                                  <button style={{float:'right'}}type="submit" className="btn btn-primary">Submit</button>
                                </div>

                            </Form>

                        );
                    }}
                </Formik>
              </section>
              <Modal
                className="modal-dialog-centered modal"
                isOpen={this.state.isResponseModalOpen}
                toggle={this.toggleResponseModal}>

                <div className="modal-body">

                  <div className="py-3 text-center">
                    <h3 className="modal-title">
                     {this.state.formSubmission === 'successful' ? (
                       <div className="text-success">
                          Message sent successfully!!
                        </div>
                      ) : null}
                     {this.state.formSubmission === 'failed' ? (
                        <div className="text-danger">
                          Message couldn't be sent
                        </div>
                      ) : null}
                    </h3>
                    <h4 className="description mt-4">
                      {this.state.formSubmission === 'successful' ? (
                        <div className="text-success">
                          <div> Our Team will get back to you shortly  </div>
                          <div> Thank you :) </div>
                        </div>
                        ): null
                      }
                      {this.state.formSubmission === 'failed' ? (
                        <div className="text-danger">
                          <div> An error occured while sending this email :( </div>
                          <div> Please try again </div>
                        </div>
                        ) : null
                      }
                    </h4>
                  </div>
                </div>

              </Modal>

            </Col>
            </div>
            </Col>


            <hr/>

            <div className="row">
              <div className=" col-lg-6 email"> Write to us on <a href="mailto: hello@uclid.com"> <b> hello@uclid.com </b> </a> </div>
              <div className="col-lg-6 careers-email">Reach us on <a href="tel:+918448444909"><b> +91 844 8444 909 </b> </a> </div>
            </div>

        </div>
      </div>
    );
  }
}

export default Contact;
