import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class NotFound extends Component {
  render() {

    return (
      <div className="">
        <Helmet>
           <meta charSet="utf-8" />
           <title>404 Error</title>
           <link rel="canonical" href="https://www.uclid.com/error" />
        </Helmet>
        <div className="pt-7 error-404-stripe">
          <div className="container" align="center">
            <h1 className="text-primary">OOPS!</h1>
            <h3 className="text-secondary">The page you were looking for doesn't exist</h3>
            <img className="error-404 pt-5 pb-5" src={require("../../images/404.gif")} />

          </div>
          <div className="" align="center">
            <a className="btn btn-primary mb-5" href="javascript:void(0)" onClick={() => { this.props.history.push('/home');}}>Home Page</a>
          </div>
        </div>

      </div>

    );
  }
}

export default NotFound;
