import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";

import LOGO from '../../images/uclid.svg';
// reactstrap components
import {
  UncontrolledCollapse,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class Header extends React.Component {

  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  render() {

    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <span className="brand">
                    <img alt="UCLID" src={LOGO} />
                </span>
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse navbar toggler="#navbar_global">
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <span className="brand">
                            <img alt="UCLID" src={LOGO} />
                        </span>
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>

                <Nav className="align-items-lg-center ml-lg-auto " navbar>

                  <NavItem>
                    <NavLink
                      className="nav-item navbar-nav-hover"
                      href="#"
                      onClick={() => { this.props.history.push('/home');}}
                      target=""
                    >
                      Home
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className="nav-item navbar-nav-hover"
                      href="#"
                      onClick={() => { this.props.history.push('/modules');}}
                      target=""
                    >
                      Product
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className="nav-item navbar-nav-hover"
                      href="#"
                      onClick={() => { this.props.history.push('/pricing');}}
                      target=""
                    >
                      Pricing
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className="nav-item"
                      href="#"
                      onClick={() => { this.props.history.push('/careers');}}
                      target=""
                    >
                      Careers
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className="nav-item"
                      href="#"
                      onClick={() => { this.props.history.push('/contact');}}
                      target=""
                    >
                      Contact
                    </NavLink>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default Header;
