import React, { Component } from 'react';
import { Container, Row, Col,Card, CardBody, CardImg,} from 'reactstrap';
import Privacy from '../../images/privacy-center-1.svg';

class PrivacyCenter extends Component {
  render() {
  document.title = 'Privacy Center';
    return (
      <div className="section-shaped section-lg">
      <div className="noise-break short">
        <div className="container col-lg-12">
          <div className="text-white">
            <h1 className="title"> Privacy Center</h1>
          </div>
          <div className="title text-white">
            <i className="text-white fa fa-calendar" aria-hidden="true"></i>
            <span className="effective-date"> Effective Date: &nbsp; December 31st, 2018 </span>
          </div>
        </div>
      </div>
      <div className="article-breadcrumbs-container">
        <div className="container">
          <ul className="breadcrumbs">
            <li>
              <a href="http://support.uclid.com/index.htm">
                <i className="fa fa-home" aria-hidden="true"></i>
              </a>
            </li>
            <li title="Uclid Help Desk">
              <a href="http://support.uclid.com/index.html">Uclid Help Desk</a>
            </li>
            <li title="Privacy Center">
              Privacy Center
            </li>

          </ul>
        </div>
      </div>
      <div className="container privacy-center">
        <div class="">
          <div class="container">
            <div class="row row-grid align-items-center">
              <div class="col-md-6 order-md-2">
                <img className="img-fluid floating" alt="Privacy Center" src={Privacy} />
              </div>
              <div class="col-md-6 order-md-1">
                <div class="pr-md-5">
                  <div class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                    <i class="ni fa fa-lock" aria-hidden="true"></i>
                  </div>
                  <h3>Your data is secure with us.</h3>
                  <p>At UCLID your data safety is our concern. We collect your personally identifiable information to that extent as to enable us provide you better user experience. We do not use, provide, reveal or tamper with your/your child’s personally identifiable information. <br/> <br/>
                  Our <a href="javascript:void(0)" onClick={() => { this.props.history.push('/privacy');}}> <b> Privacy Policy </b> </a>clearly lays down what sort of personal information we collect, how & why we store it. Beyond the terms of the privacy policy we do not access your data.</p>
                  <ul class="list-unstyled mt-5">
                    <li class="py-2">
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="badge badge-circle badge-danger mr-3">
                            <i class="ni fa fa-check" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div>
                          <h6 class="mb-0">Your data safety is our promise</h6>
                        </div>
                      </div>
                    </li>
                    <li class="py-2">
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="badge badge-circle badge-success mr-3">
                            <i class="ni fa fa-check" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div>
                          <h6 class="mb-0">we do not access your data.</h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>



      <div className="article-body">
      <div className="category-page">
          <div className="loc-banner-container"></div>
          <div className="category-container">
              <div className="category-trees policies">
                  <div className="section-tree-with-article">
                    <ul>
                      <li className="section"><a className="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                      <h2 title="Legally Compliant">We are Legally Compliant with Data privacy.</h2>
                      <ul>
                          Uclid is compliant with the <a href=""> <b> Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 </b> </a> which gives clear stipulations on who can collect personal data and for what purpose. We also ensure that our App is in consonance with the Information Technology Act, 2000.
                      </ul><br/>
                      <ul>
                        We are always on the lookout for latest amendments to regulatory frame works and will keep our policies updated accordingly. We shall also adhere strictly with the forthcoming “Personal Data Protection Act, 2018,” after June 2019. We shall update or privacy policy and keep you updated in this page.
                      </ul>
                      </li>
                    </ul>
                  </div>
              </div>
          </div>

          <section className="pt-5 section oath">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="card bg-gradient-secondary">
                    <div className="card-body p-lg-5">
                    <h5 className="display-4 text-primary text-center pb-3">UCLID OATH</h5>
                    <h5 className=""> “We solemnly promise that we shall never harm you, your child or your organization by misusing, distorting or selling your personally identifiable information or any information that is generated there with by your usage of the App.”</h5>
                      <h5 className="description slug pt-4">- Founders of UCLID</h5>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </section>
      </div>
      </div>

  </div>
  </div>
    );
  }
}

export default PrivacyCenter;
