import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup' ;
import axios from'axios';
import {Helmet} from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import classnames from "classnames";

import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  Button, Form, FormFeedback, FormGroup, Label, Input,FormText,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

class Careers extends Component {
  state = {
    iconTabs: 1,
    plainTabs: 1,
    modal: false,
    isResponseModalOpen: false,
    formSubmission: null,
  };

  validateDob = () => {
    if(this.state.dob){
      let currentDate = new Date();
      let age = ((currentDate.getTime() - this.state.dob.getTime()) / ( 1000 * 3600 * 24 * 365 ));
      if(age > 18 && age <60){
        return 'accept'
      } else {
        return 'ageProblem'
      }
    }
    return 'required'
  }

  getDobString = (dob) => {
    let year = dob.getFullYear();
    let month = dob.getMonth();
    month = month < 10 ? '0'+month : month;
    let date = dob.getDate();
    date = date < 10 ? '0'+date : date;

    return `${year}-${month}-${date}`
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  toggleResponseModal = () => {
   this.setState((prevState) => {
     return {
       isResponseModalOpen: !prevState.isResponseModalOpen
     }
   })
 }

 autoCloseResponseModal = () => {
   setTimeout(() => {
     this.setState({
       isResponseModalOpen: false
     })
   }, 4000)
 }

  onSubmit = (values, { setSubmitting, resetForm }) => {

      setSubmitting(false);

      let data = new FormData();

      let dob = this.state.dob;
      if(!dob){
        return
      }

      if(values){
        data.append('fullName', values.name);
        data.append('email', values.email);
        data.append('phoneNumber', values.number);
        data.append('dob', this.getDobString(dob));
        data.append('gender', values.gender);
        data.append('currentLocation', values.location);
        data.append('companyName', values.organization);
        data.append('designation', values.designation);
        data.append('workExperience', values.experience);
        data.append('annualSalary', values.salary);
        data.append('expectedSalary', values.expected);
        data.append('noticePeriod', values.noticePeriod);
        data.append('applyingFor', values.role);
        data.append('message', values.message);
        data.append('note', values.message);
        data.append('status', 'ur');
        data.append('attachment', this.state.resumeFile);

      }


      this.setState({
        formSubmission: 'ongoing',
        isResponseModalOpen: true
      });
      axios.post(
        'http://13.233.38.145/uclid-job-application/add',
        data,
        {headers: { 'content-type': 'multipart/form-data' }}
      )
      .then((res) => {
        console.log(res);
        if(res.status === 200){
          this.setState({
            formSubmission : 'successful',
            isResponseModalOpen: true,
            dob: null
          })
          this.autoCloseResponseModal()
          this.toggle();
          setTimeout(() => {
            resetForm({
              name:"",
              email:"",
              number:"",
              location:"",
              gender:"",
              organization:"",
              file: "",
              designation:"",
              experience:"",
              salary:"",
              select:"",
              expected:"",
              message:"",
              dob: "",
              resume:"",
              noticePeriod:"",
              role:""});
          }, 2000);
        } else {
          this.setState({
            formSubmission: 'failed',
            isResponseModalOpen: true
          })
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          formSubmission : 'failed',
          isResponseModalOpen: true
        })
      })
  };



  schema = () => {
     return Yup.object().shape({
         name: Yup.string()
             .required("Full Name is required"),
         email: Yup.string()
             .required("Email id is required")
             .email(" Email id is invalid"),
         number: Yup.string()
             .required("Mobile Number is required")
             .matches(/(?=.*\d)/, 'mobile number must contain: numbers only')
             .min(10, 'Mobile number must be 10 digits')
             .max(10, 'Mobile number must be 10 digits'),
         gender: Yup.string()
             .required("Gender is required"),
         location: Yup.string()
             .required("Current Location is required"),
         // organization: Yup.string()
         //     .required("Organization Name is required"),
         // designation: Yup.string()
         //     .required("Designation is required"),
         // experience: Yup.string()
         //     .required("Experience is required"),
         // salary: Yup.string()
         //     .required("Annual Salary is required"),
         expected: Yup.string()
             .required("Expected Salary is required"),
         // message: Yup.string()
         //     .required("Enter your message"),
         // noticePeriod: Yup.string()
         //     .required("Notice Period required"),
         role: Yup.string()
             .required("Role is required"),
         // dob: Yup
         //     .test('required','Date of Birth is required', () => this.state.dob)
         //     .test('isAge18', 'Sorry, You must be between 18-60 to apply',
         //      () => {
         //        let date = `${(new Date()).getFullYear()}-${(new Date()).getMonth()}-${(new Date()).getDate()}`;
         //        if(this.state.dob) {
         //          date = `${this.state.dob.getFullYear()}-${this.state.dob.getMonth()}-${this.state.dob.getDate()}`
         //        }
         //        let [year, month, day] = date.split('-');
         //        let d1 = (new Date()).setFullYear(year, month-1, day);
         //        let d2 = new Date();
         //        let age = ((d2.getTime() - d1) / ( 1000 * 3600 * 24 * 365 ));
         //        return d1 && age > 17.9 && age < 60
         //      }
         //     ),

          resume: Yup.string()
          .required('Resume is required')
          .test('fileType',
            `Only pdf's and doc's are supported`,
            () => {
              let file = this.state.resumeFile ? this.state.resumeFile : null;
              if(file){
                return (file.type === 'application/msword' || file.type === 'application/pdf')
              } else {
                return false
              }
            }
          )
          .test('fileSize',
            'Maximum 600kb allowed',
            () => {
              let file = this.state.resumeFile ? this.state.resumeFile : null;
              if(file){
              return file.size < 600001;
              } else {
                return false;
              }
            }
          )
     })
  };


  render() {
    return (
      <div className="">
        <Helmet>
           <meta charSet="utf-8" />
           <title>Careers</title>
           <meta name="description" content="Uclid is an Edtech startup doing it’s part in creating powerful education ecosystem. Uclid is a global product dedicated to empower educational organizations of any size or any nature in their journey towards excellence."/>
           <meta name="keywords" content="Hiring, full stack developer, front end developers, customer relationship managers, business development executives. "/>
           <link rel="canonical" href="https://www.uclid.com/careers" />
        </Helmet>

        <div className="pt-7 pb-7">

      {/* <div className="careers">
          <div className="career s1"></div>
          <div className="career s2"></div>
          <div className="career s3"></div>
        </div> */}





        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-5 ">
            <div className="row pt-lg-lg">
              <h2 className="about-title pb-4">Let’s create magic together!</h2>
              <h4>We are looking for talented and passionate individuals to join our growing team.</h4>
              <p className="description pt-4">Located in cyber-city Hyderabad which has become one of India’s premiere information technology hubs, Uclid is an Edtech startup doing it’s part in creating powerful education ecosystem. Uclid is a global product dedicated to empower educational organizations of any size or any nature in their journey towards excellence. <br/> <br/>
              UCLID’s culture is driven by mutual respect, transparency in operations and cross collaborations of teams. We have an open door policy & purpose driven roles minus hierarchies. It’s our thirst to innovate and our shared belief in UCLID’s core values that drives us as a Team. We love to rely and trust each other, after all didn't the pack survive?!</p>
              {/* <img className="team" src={require('../../images/svg/careers.svg')}/> */}
            </div>
          </div>

            <h4 className="text-subheading display-3 pb-4" align="center">Life at UCLID</h4>
              <div className="row col-lg-12 pb-5 gallery">
                <div class="wrapper">
                  <div class="column">
                    <div class="inner shadow"></div>
                  </div>
                  <div class="column">
                    <div class="inner "></div>
                  </div>
                  <div class="column">
                    <div class="inner"></div>
                  </div>
                  <div class="column">
                    <div class="inner"></div>
                  </div>
                  <div class="column">
                    <div class="inner"></div>
                  </div>
                  <div class="column">
                    <div class="inner"></div>
                  </div>
                  <div class="column">
                    <div class="inner"></div>
                  </div>
                  <div class="column">
                    <div class="inner"></div>
                  </div>
                  <div class="column">
                    <div class="inner"></div>
                  </div>
                  <div class="column">
                    <div class="inner"></div>
                  </div>
                  <div class="column">
                    <div class="inner"></div>
                  </div>
                  <div class="column">
                    <div class="inner"></div>
                  </div>
                </div>
              </div>
          </div>

          <div className="bg-secondary pb-5">
            <div className="container perks pt-4">
              <h4 className="text-subheading display-3" align="center">Perks</h4>
                <ul className="description-list perks-points">
                  <li>Flexible working hours</li>
                  <li>Work with the talented team</li>
                  <li>Global product</li>
                  <li>Prestigious clients</li>
                  <li>Work from home when needed</li>
                  <li>Regular Team outings</li>
                  <li>Welcome pack</li>
                  <li>Multi cultural environment</li>
                  <li>Team activities</li>
                  <li>Occasional Gaming events</li>
                  <li>Personal Development focus</li>
                  <li>Sponsored Team Holidays</li>
                </ul>
            </div>
          </div>


          <Col className="mt-5 pt-7 mt-lg-0 container" lg="10">
            <div className="row">
            <Col lg={{ size: 3}} md={{ size: 3 }} sm={{ size: 12}} className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-column"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 1
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                    href="#pablo"
                    role="tab"
                  >
                    Full Stack Developer
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 2
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                    href="#pablo"
                    role="tab"
                  >
                    Front End Developer
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 3}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 3
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 3)}
                    href="#pablo"
                    role="tab"
                  >
                    Customer Relationship Manager
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 4}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 4
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 4)}
                    href="#pablo"
                    role="tab"
                  >
                    Marketing Associates
                  </NavLink>
                </NavItem>


              </Nav>
            </Col>
            <Col lg={{ size: 8, offset: 1}} md={{ size: 8, offset: 1}} sm={{ size: 12}} >
            <Card className="careers-pill shadow">
              <CardBody>
                <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
                  <TabPane tabId="plainTabs1">
                    <div className="description">

                    <h5 className="display-4">Full Stack Developer</h5> <br/>

                    Team UCLID seeks outstanding and ambitious developers who enjoy working both on front-end and back-end development. The position offered is most suited for those who are desirous of building cutting edge online apps and wish to use their expertise to impact a real change on a product used globally. We are looking for team players with dedication and self-motivation who can mould themselves in accordance to new challenges.

                    <br/> <br/>

                    <b className="title" > Role & Responsibilities: </b> <br/> <br/>
                    As a full-stack developer in the UCLID tech team, you will be required to develop and improve features built primarily in JavaScript, React (Web/ Native), Node.js and MongoDB. You will be creating production grade JavaScript and Node JS code and will have the opportunity to implement solutions designed to handle enormously high levels of traffic. You will need to be highly process driven and yearn to learn new technologies. You will also be communicating directly with our users on a regular basis to understand various issues and truly understand how your work is being used.

                    <br/> <br/>

                    <b className="title" > Experience: </b> &nbsp; 2 - 4 Years

                    <br/> <br/>


                    <b className="title" >  Requirements : </b> <br/> <br/>
                      <ul className="description-list">
                        <li>  Strong background in benchmarking, optimization, scaling and dealing with high levels of traffic </li>
                        <li>  Experience using JavaScript, React JS, Node JS and MongoDB. </li>
                        <li>  Familiarity with Git. </li>
                        <li>  Fluent in English with good written and verbal communication skills. </li>
                        <li>  Ability to work independently - accountable for your own actions and <br/> able to act with both urgency and integrity. </li>
                        <li>  Must submit to a background check confidentially processed by our third-party</li>
                      </ul>

                    <br/>

                    <b className="title" >  Compensation Range : </b> <br/> <br/>

                    Compensation offered to a successful candidate will be based on relative experience and skills.

                   </div>
                  </TabPane>

                  <TabPane tabId="plainTabs2">
                    <div className="description">

                    <h5 className="display-4">Front End Developer</h5> <br/>

                    Team UCLID seeks outstanding and ambitious developers who enjoy working on front-end (ReactJS/React Native). The positions offered are most suited for those who are desirous of building cutting edge online apps and wish to use their expertise to impact a real change on a product used globally. We seek team players with dedication and self-motivation, who can mould themselves to new challenges. We are looking for Immediate Joining. Below is the JD & other details.


                    <br/> <br/>

                    <b className="title" > Role & Responsibilities: </b> <br/> <br/>
                    As a Front-End developer in the UCLID tech team, you will be required to develop and improve features built primarily in JavaScript, React (Web/ Native). You will need to be highly process driven and yearn to learn new technologies. You will also be communicating directly with our users on a regular basis to understand various issues and truly understand how your work is being used.


                    <br/> <br/>


                    <b className="title" >  Requirements : </b> <br/> <br/>
                      <ul className="description-list">
                        <li>  Experience using JavaScript, React JS. </li>
                        <li>  Familiarity with Git. </li>
                        <li>  Fluent in English with good written and verbal communication skills. </li>
                        <li>  Ability to work independently - accountable for your own actions and <br/> able to act with both urgency and integrity. </li>
                        <li>  Must submit to a background check confidentially processed by our third-party</li>
                      </ul>


                    <br/>

                    <b className="title" > Experience: </b> &nbsp; 2 - 4 Years

                    <br/> <br/>

                    <b className="title" >  Compensation Range : </b> <br/> <br/>

                    Compensation offered to a successful candidate will be based on relative experience and skills.

                   </div>
                  </TabPane>

                  <TabPane tabId="plainTabs3">
                    <div className="description">

                    <h5 className="display-4">Customer Relationship Manager</h5> <br/>

                    We're looking for an empathetic and self motivated Support Specialist to join our growing Customer Experience team. You'll be an important part of Uclid app 'Setup' process, helping our customers with their organization creation, configuration and device requirements assistance as well as app usage guidance. You will need to go beyond supporting the clients with uclid app setup & configuration in their organization setup & configuration but will also be an important part of relationship management, making sure that they have the best experience using UCLID APP. <br/> <br/>

                    You are required to be an enthusiastic problem solver with an analytical mindset and a determination to find and resolve issues. Your customer empathy must come through in your strong writing style, adapting to our user's tone of voice. You are expected to be comfortable adapting quickly and openly to changes, and keen to constantly learn and apply these learnings to your work.

                    <br/> <br/>

                    <b className="title" >You will: </b> <br/> <br/>
                    <ul className="description-list">
                      <li>  Support our users in setting up their 'Virtual organization' on UCLID.</li>
                      <li>  Communicate crucial company updates through various channels (i.e., in-app messages, emails, etc.) </li>
                      <li>  Manage the knowledge base content that cover setup and usage of APP. This includes writing and updating existing content, as well as creating visual content (like videos!) </li>
                      <li>  Create reports for the Managing team that highlight customer feedback / sentiment. </li>
                      <li>  Evaluate results from tests and gather data to decide on next steps within Customer relationship team. </li>
                      <li>  Collaborate with the cross-departmental Development team to plan and execute new requirements.</li>
                    </ul>

                    <br/>

                    <b className="title" > Experience: </b> &nbsp; 0 - 4 Years

                    <br/> <br/>

                    <b className="title" >  Compensation Range : </b> <br/> <br/>

                    The annual compensation for this role ranges between INR 120000 to INR 150,000. The actual compensation offered to a successful candidate will be based on relative experience and skills.

                    <br/> <br/>

                    <b className="title" >Requirements: </b> <br/> <br/>
                    <ul className="description-list">
                      <li>  1 year in a customer-facing role, ideally support would be preferred. </li>
                      <li>  Experience and enthusiasm in using Apps.</li>
                      <li>  Fluent in English with good written and verbal communication skills.  </li>
                      <li>  Ability to work independently - accountable for your own actions and able to act with both urgency and integrity.  </li>
                      <li>  Desire to work in a respectful, transparent, and collaborative work environment, following UCLID's company values, culture and ways of working </li>
                      <li>  Must submit to a background check confidentially processed by our third-party.</li>
                    </ul>


                    <br/> <br/>
                   </div>
                  </TabPane>

                  <TabPane tabId="plainTabs4">
                    <div className="description">

                    <h5 className="display-4">Marketing Associates</h5> <br/>

                    We're looking for a customer-centric and product-focused sales specialist who is passionate about acquiring clients. <br/>
                    You would join our growing team and help us develop new sales programs that are personable and educational, but also scalable. You'll also be responsible in building a sales pipeline that is effective in ensuring successful on-boarding during trials, and closing the ideal leads. <br/>

                    We're looking for applicants who will excel in a multi-faceted role. You'll spend a lot of time talking with leads and getting to know our ideal customers. The Sales team works closely with our Customer support, Tech and legal teams to ensure that customers have a smooth experience getting started and continuing their use of UCLID. <br/>

                    As the team grows, you'll also help determine strategy and develop plans for increasing expansions and retentions. <br/>

                    Our ideal team member for this role is numbers-oriented and adaptable to working with a high volume of leads. You should be as comfortable thinking through how to approach Enterprise sales with high-touch, personable interactions that educate our leads. <br/>


                    <br/> <br/>

                    <b className="title" >In this role you'll: </b> <br/> <br/>
                    <ul className="description-list">
                      <li>  Perform product demos and present webinars for both prospects and customers.</li>
                      <li>  Refine and develop how we converse with prospective clients to ensure they are educated on what they can achieve with our product. </li>
                      <li>  Identify ways to improve our on boarding experience. </li>
                      <li>  Develop and maintain a sales opportunity and forecast system. </li>
                      <li>  Collaborate with the Customer Relation team both during the sales pipeline and at the handover stage. </li>
                      <li>  Collaborate with leadership to help determine pricing and product line strategy, as well as to identify new markets we can enter and develop plans to do so effectively.</li>
                      <li>  Communicate prospective customer needs to product managers and product teams.</li>
                      <li>  Help establish training material and programmes as the Sales team grows.</li>
                      <li>  Must submit to a background check confidentially processed by our third-party.</li>
                    </ul>


                    <br/> <br/>

                    <b className="title" >  Compensation Range : </b> <br/> <br/>

                    Compensation offered to a successful candidate will be based on relative experience and skills.

                    <br/> <br/>

                    <b className="title" >Requirements: </b> <br/> <br/>
                    <ul className="description-list">
                      <li>  1+ years of inbound sales experience is preferable.</li>
                      <li>  Customer-facing experience in a technology environment - empathetic to the challenges customers face. </li>
                      <li>  Product savvy - able to develop strong understanding and technical knowledge in order to speak confidently to customers and communicate their needs to our team. </li>
                      <li>  Be comfortable taking calls and closing deals while also thinking strategically about growing our sales efforts. </li>
                      <li>  Experience adapting sales skills for a dedicated customer base, particularly for education field. </li>
                      <li>  Fluent in English with good written and verbal communication skills. </li>
                      <li>  Ability to work independently - accountable for your own actions and able to act with both urgency and integrity.</li>
                      <li>  Naturally empathetic - must be comfortable talking to different types of potential customers from Education sector.</li>
                      <li>  Must submit to a background check confidentially processed by our third-party.</li>
                    </ul>


                    <br/> <br/>
                   </div>
                  </TabPane>

                </TabContent>
              </CardBody>
            </Card>

            <div className="apply-now">
              <Button
                className="btn-icon btn-2 ml-1"
                color="primary"
                type="button"
                onClick={this.toggle}
              >

                <span className="btn-inner--icon">
                  Apply Now
                </span>

              </Button>
              <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
              <ModalHeader toggle={this.toggle}>Apply Now</ModalHeader>
              <ModalBody>
              <Col className="pt-3 pb-5" lg={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} sm={{ size: 12}}>
                  <Formik
                      enableReinitialize={true}
                      initialValues={{name:"",
                                      email:"",
                                      number:"",
                                      location:"",
                                      gender:"",
                                      organization:"",
                                      file: "",
                                      designation:"",
                                      experience:"",
                                      salary:"",
                                      select:"",
                                      expected:"",
                                      message:"",
                                      dob: "",
                                      resume:"",
                                      noticePeriod:"",
                                      role:""}}
                      onSubmit={this.onSubmit}
                      validationSchema={this.schema}
                  >
                      {props => {
                          const {
                              handleSubmit,
                              values,
                              touched,
                              errors,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                              setTouched
                          } = props;
                          return (
                              <Form onSubmit={handleSubmit} noValidate>
                                  <FormGroup>
                                      <Label htmlFor={'name'}>Full Name</Label>
                                      <Input type="text"
                                              name={'name'}
                                              id={'name'}
                                              placeholder="Enter your Full Name"
                                              invalid={touched['name'] && !!errors['name']}
                                              required
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values['name']} />
                                      <FormFeedback>{errors['name']}</FormFeedback>
                                  </FormGroup>

                                  <FormGroup>
                                      <Label htmlFor={'email'}>Email</Label>
                                      <Input type="text"
                                              name={'email'}
                                              id={'email'}
                                              placeholder="Enter your Email"
                                              invalid={touched['email'] && !!errors['email']}
                                              required
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values['email']} />
                                      <FormFeedback>{errors['email']}</FormFeedback>
                                  </FormGroup>
                                  <FormGroup>
                                      <Label htmlFor={'number'}>Mobile Number</Label>
                                      <Input type="text"
                                              name={'number'}
                                              id={'number'}
                                              placeholder="Enter your Mobile Number"
                                              invalid={touched['number'] && !!errors['number']}
                                              required
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values['number']} />
                                      <FormFeedback>{errors['number']}</FormFeedback>
                                  </FormGroup>

                                  <FormGroup>
                                    <Label htmlFor={'gender'}>Gender</Label>
                                    <Col sm={10}>
                                      <FormGroup check>
                                        <Label check>
                                          <Input
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="radio"
                                            name="gender"
                                            value="M" />{' '}
                                          Male
                                        </Label>
                                      </FormGroup>
                                      <FormGroup check>
                                        <Label check>
                                          <Input
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="radio"
                                            name="gender"
                                            value="F" />{' '}
                                          Female
                                        </Label>
                                      </FormGroup>
                                    </Col>
                                    <FormFeedback style={touched['gender']?{display:'block'}:null}>{errors['gender']}</FormFeedback>
                                  </FormGroup>
                                  <FormGroup>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for="dob">Date of Birth</Label>
                                    <DatePicker
                                      className={touched['dob'] && this.validateDob() !== 'accept' ? 'form-control redBorder' : 'form-control'}
                                      dateFormat="yyyy/MM/dd"
                                      selected={this.state.dob}
                                      onChange={(date) => {
                                        this.setState({
                                          dob:date,
                                        })
                                        setTouched({'dob': true})
                                      }}
                                      placeholderText="YYYY/MM/DD"
                                      />
                                      {this.validateDob() === 'ageProblem' ? <div className="invalid">You must be between 18-60 to apply</div> : null}
                                      {touched['dob'] && this.validateDob() === 'required' ? <div className="invalid">Date of Birth is required</div> : null}
                                  </FormGroup>

                                  <FormGroup>
                                      <Label htmlFor={'location'}>Current Location</Label>
                                      <Input type="text"
                                              name={'location'}
                                              id={'location'}
                                              placeholder="Enter your Current Location"
                                              invalid={touched['location'] && !!errors['location']}
                                              required
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values['location']} />
                                      <FormFeedback>{errors['location']}</FormFeedback>
                                  </FormGroup>



                                  <FormGroup>
                                      <Label htmlFor={'organization'}>Company Name </Label>
                                      <Input type="text"
                                              name={'organization'}
                                              id={'organization'}
                                              placeholder="Enter your current / previous company name (if applicable)"
                                              invalid={touched['organization'] && !!errors['organization']}
                                              required
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values['organization']} />
                                      <FormFeedback>{errors['organization']}</FormFeedback>
                                  </FormGroup>

                                  <FormGroup>
                                      <Label htmlFor={'designation'}>Designation</Label>
                                      <Input type="text"
                                              name={'designation'}
                                              id={'designation'}
                                              placeholder="Enter your designation (if applicable)"
                                              invalid={touched['designation'] && !!errors['designation']}
                                              required
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values['designation']} />
                                      <FormFeedback>{errors['designation']}</FormFeedback>
                                  </FormGroup>

                                  <FormGroup>
                                      <Label htmlFor={'experience'}>Experience</Label>
                                      <Input type="text"
                                              name={'experience'}
                                              id={'experience'}
                                              placeholder="Enter your experience (if applicable)"
                                              invalid={touched['experience'] && !!errors['experience']}
                                              required
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values['experience']} />
                                      <FormFeedback>{errors['experience']}</FormFeedback>
                                  </FormGroup>

                                  <FormGroup>
                                      <Label htmlFor={'salary'}>Annual Salary</Label>
                                      <Input type="text"
                                              name={'salary'}
                                              id={'salary'}
                                              placeholder="Enter your Annual Salary (if applicable)"
                                              invalid={touched['salary'] && !!errors['salary']}
                                              required
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values['salary']} />
                                      <FormFeedback>{errors['salary']}</FormFeedback>
                                  </FormGroup>

                                  <FormGroup>
                                      <Label htmlFor={'expected'}>Expected Salary</Label>
                                      <Input type="text"
                                              name={'expected'}
                                              id={'expected'}
                                              placeholder="Enter your Expected Salary"
                                              invalid={touched['expected'] && !!errors['expected']}
                                              required
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values['expected']} />
                                      <FormFeedback>{errors['expected']}</FormFeedback>
                                  </FormGroup>

                                  <FormGroup>
                                    <Label for="exampleSelect">Notice Period</Label>
                                    <Input
                                      type="select"
                                      name="noticePeriod"
                                      id="noticePeriod"
                                      invalid={touched['noticePeriod'] && !!errors['noticePeriod']}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      required
                                      value={values['noticePeriod']}>
                                      <option>Available Now</option>
                                      <option>10 Days</option>
                                      <option>15 Days</option>
                                      <option>20 Days</option>
                                      <option>25 Days</option>
                                      <option>30 Days</option>
                                      <option>45 Days</option>
                                      <option>60 Days</option>
                                    </Input>
                                    <FormFeedback>{errors['noticePeriod']}</FormFeedback>
                                  </FormGroup>

                                  <FormGroup>
                                    <Label for="exampleSelect">Applying For</Label>
                                    <Input
                                      type="select"
                                      name="role"
                                      id="role"
                                      invalid={touched['role'] && !!errors['role']}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      required
                                      value={values['role']}>
                                      <option>Role</option>
                                      <option>Front-End Developer</option>
                                      <option>Full-Stack Developer</option>
                                      <option>Marketing Associates</option>
                                      <option>Customer Relationship Manager</option>
                                    </Input>
                                    <FormFeedback>{errors['role']}</FormFeedback>
                                  </FormGroup>

                                  <FormGroup>
                                    <Label for="resume">Attach Resume</Label>
                                      <Input
                                        invalid={touched['resume'] && !!errors['resume']}
                                        onChange={(event) => {
                                          handleChange(event);
                                          this.setState({
                                            resumeFile: event.currentTarget.files[0]
                                          })
                                        }}
                                        onBlur={handleBlur}
                                        type="file"
                                        name="resume"
                                        id='resume'
                                      />
                                      <FormFeedback>{errors['resume']}</FormFeedback>
                                  </FormGroup>


                                  <FormGroup>
                                      <Label htmlFor={'message'}>Message</Label>
                                      <Input type="textarea"
                                              name={'message'}
                                              id={'message'}
                                              placeholder="Drop your message here (if any)"
                                              invalid={touched['message'] && !!errors['message']}
                                              required
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values['message']} />
                                      <FormFeedback>{errors['message']}</FormFeedback>
                                  </FormGroup>

                                    <div>
                                      <button style={{float:'right'}}type="submit" className="mt-5 btn btn-primary">
                                        Submit
                                      </button>
                                    </div>

                              </Form>

                          );
                      }}
                  </Formik>
                </Col>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.isResponseModalOpen} toggle={this.toggleResponseModal}>

              <div className="modal-body">
              <div className="py-3 text-center">
                <h3 className="modal-title">
                 {this.state.formSubmission === 'successful' ? (
                     <div className="text-success">
                        Applied Successfully
                     </div>
                  ) : null}
                 {this.state.formSubmission === 'failed' ? (
                    <div className="text-danger">
                      Error occured while applying
                    </div>
                  ) : null}
                </h3>
                <h4 className="description mt-4">
                  {this.state.formSubmission === 'successful' ? (
                    <div className="text-success">
                      <div>Our Team will get back to you shortly. </div>
                      <div> Thank you :) </div>
                    </div>
                    ): null
                  }
                  {this.state.formSubmission === 'failed' ? (
                    <div className="text-danger">
                      <div> An error occured while applying for this postion :( </div>
                      <div> Please try again. </div>
                    </div>
                    ) : null
                  }
                </h4>
              </div>
              </div>

            </Modal>

            </div>

            </Col>
            </div>
          </Col>




        </div>
      </div>
    );
  }
}

export default Careers;
