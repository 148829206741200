import React, { Component } from 'react';
import {Helmet} from "react-helmet";
class TeachersDay extends Component {
  render() {
    return (
      <div>

        <Helmet>
           <meta charSet="utf-8" />
           <title>Did we thank our teachers yet?</title>
           <meta name="description" content="In the advanced age of technology, edtech facilitates educational organizations to provide students with quick access to information"/>
           <meta name="keywords" content="challenges in use of edtech by organizations, virtual organizational portfolios, easy data movement, increase efficiency in assessment, cost efficient implementation, communicative networking solution, need for a unified network, technology in academics"/>
           <link rel="canonical" href="https://www.uclid.com/technology-for-teachers" />
        </Helmet>

        <main className="profile-page">
          <section className="section-profile-cover section-shaped my-0">
            {/* <!-- Circles background -->*/}

            <div className="shape shape-style-1 shape-default alpha-4">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            {/* SVG separator --> */}
            <div className="separator separator-bottom separator-skew">
              <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <polygon className="fill-white" points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div>
          </section>
          <section className="section">
            <div className="container">
              <div className="card card-profile shadow mt--300">
                <div className="px-4">
                  <div className="row justify-content-center">
                    <div className="col-lg-12 order-lg-2">
                      <div className="card-profile-image">
                        <a href="#">
                          <img src={require('../../../images/blog/blog-images/teachersday.jpg')} />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="mt-20  py-5">
                    <div className="col-lg-12 mt-xxl order-lg-2">
                      <h3 className="text-primary blog-title">Did we thank our teachers yet?</h3>
                    </div>
                    <div className="row justify-content-center py-5 border-top blog-content">
                      <div className="col-lg-9">
                        <p><b>Teaching is the most responsible profession one can take up. Don’t agree?</b> </p>
                        <p>State actors, stage actors, fire fighters & every other saviours in disguise owe their skill to the humble yet noble teachers! It is interesting to keep celebrating teacher’s day every year though we really don’t celebrate their enormous efforts day in and day out. </p>
                        <p>Managing a single child at home seems like a mission impossible and imagine managing scores of such kids whom you don’t biologically own is indeed a huge task! Gives me the goose skin for sure. Let me tell you an interesting fact – teachers are the most stressed out managers we can find around. Punching numbers in their heads, sticking to awful timelines, rushing towards deadlines, pleasing people, cramming away subjects along with kids, gobbling up cold food & smiling at the child giving away a birthday toffee...a teacher does it all and much more. </p>
                        <p>As a 90’s kid, I saw my most favourite teacher dedicate her energies into assessing answer sheets of kids manually. Burning the midnight oil, she would meticulously analyse terrible hand writings and push over mark by mark making sense out of every little scribbling on the paper. The day when she would give back the answer sheets, each one of us would start begging her for a mark or half & literally fall at her heels like it’s the last minute of a super sale! Parents would altogether start another war of their own during the PTA meets.</p>
                        <p>Then came 21st century generation and we got a plethora of tools that a teacher could use and maybe take a breath in all the chaos of corporate education. The era of LMS gave respite teachers by doing away with endless paper work. Redundancies in performing classroom activities have been curbed to an extent. But, there are many among teachers who, in this day and age too are averse to or ignorant to use technology especially if it’s a desktop to enter data which would simplify their tasks further. Mobiles are a seemingly easier option for all and sundry. Our dear teachers too are comfortable carrying these little minions of mobile in their palms and receive incessant beeps & blinks of texts and reminders. Keeping them in mind each second while we engineered the Uclid app, we dedicated our UI & UX to cater to teachers as a minimum typing application. </p>
                        <p>
                        Updating Diary is a breeze and yes, we didn’t re-invent the wheel but we surely are able to save 30-60 minutes of their time daily! Making announcements is supremely customisable and again we don’t claim a break through here. Announcements, report cards, assignment, time tables and feedback are on our platform to share their burden. We intend to make their work happen easy and quick all with just a few clicks. An amazing lesson planner with minute progress tracking, deep/smart academic analytics is our first course of amplifying a teacher’s impact with technology. We made sure to create a focused, diversion free, free of cost communication platform where parents can actually be part of a child’s classroom everyday and teachers can savour their personal  time & space which is near to impossible while they use regular social media for communication. 
                        </p>
                        <h6 className="blog-heading"> Uclid is because teachers are, Uclid is our way of thanking them! </h6>
                        <p>
                        This teacher’s day honour your teachers by empowering them with right technology so that they too can reap benefits of ‘the information age’ and thank them for making you worth your salt.
                        </p>
                      </div>
                    </div>

                    {/* <div class="fb-share-button" 
                      data-href="https://uclid.com/technology-for-teachers" data-layout="button" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Share</a>
                    </div> */}
                  </div>
                </div>
              </div>
              
            </div>
          </section>
         
        </main>

      </div>
    );
  }
}

export default TeachersDay;
