import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class Pricing extends Component {
  render() {
    document.title = 'Pricing';
    return (
      <div className="">
        <Helmet>
           <meta charSet="utf-8" />
           <title>Pricing</title>
           <meta name="description" content="Uclid has both free and premium modules making it viable for every organization to use uclid."/>
           <meta name="keywords" content="LMS, download, free, IOS, Android "/>
           <link rel="canonical" href="https://www.uclid.com/pricing" />
        </Helmet>
        <div className="pt-7 pb-7">
          <header className="Header">
            <div className="pricing-background ">
              <div className="uclid u0"></div>
              <div className="uclid u2 "></div>
            </div>

            <div className="pricing-background accelerated ">
              <div className="uclid u1"></div>
              <div className="uclid u3 pattern"></div>
              <div className="uclid u4"></div>
            </div>
          </header>
          <div className="container pt-7">
            <div className="row">
                <div className="col-md-12">
                    <div className="row-title">Uclid for Everyone!</div>
                </div>
            </div>

          <div className="row">
                <div className="col-sm-6 col-md-6 col-sm-12">
                    <div className="pricing-box pricing-color1">
                        <div className="pricing-content">
                            <div className="pricing-icon shadow">
                                <img src={require('../../images/icons/freemium.svg')} />
                            </div>
                            <div className="pricing-title">Uclid Freemium</div>
                            <div className="pricing-details">
                                <ul> <div className="pricing-title-module">Digitalize Academics </div>
                                    <li>Virtual Organization Creation</li>
                                    <li>Course Management</li>
                                    <li>User Profile</li>
                                    <li>Time Table</li>
                                    <li>Assignment</li>
                                    <li>Attendance</li>
                                    <li>Lesson Plan</li>
                                    <li>Feedback</li>
                                    <li>Certifications</li>
                                </ul>
                                <ul> <div className="pricing-title-module">Communicate with peers </div>
                                    <li>Announcement</li>
                                    <li>Messaging</li>
                                    <li>Grievance Management</li>
                                    <li>Guardian Connect</li>
                                    <li>Discussions</li>
                                    <li>Groups</li>
                                </ul>
                            </div>
                            <div className="pricing-link">
                              Download Now
                              <a className="button-ios bold" href="https://itunes.apple.com/in/app/uclid/id1452459085#?platform=iphone">
                                <img src={require('../../images/ios.svg')}/></a>
                              <a className="" href="https://play.google.com/store/apps/details?id=com.uclid.mobile&hl=en">
                                <img src={require('../../images/android.svg')}/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-sm-12">
                    <div className="pricing-box pricing-color2 featured">
                        <div className="pricing-content">
                            <div className="pricing-icon shadow">
                                <img src={require('../../images/icons/premium.svg')} />
                            </div>
                            <div className="pricing-title">Uclid Premium</div>
                            <div className="pricing-details">
                                <ul> <div className="pricing-title-module">Progress Management Tools </div>
                                    <li>Exams</li>
                                    <li>Report Card</li>
                                    <li>Portfolio</li>
                                    <li>Analytics</li>
                                    <li>SMS credits for offline users</li>
                                </ul>
                                <ul> <div className="pricing-title-module">Operational Tools </div>
                                    <li>Admissions</li>
                                    <li>Online / offline Fee Payments</li>
                                    <li>Transportation tracking system</li>
                                </ul>
                                <ul> <div className="pricing-title-module">Dedicated Account Manager </div>
                                    <li>Organization setup support</li>
                                    <li>Data migration support</li>
                                    <li>Dedicated App usage resource</li>
                                </ul>
                                <h5 className="description text-primary pt-4 pb-5 text-center"> Though its premium, its highly cost efficient </h5>
                            </div>

                            <div className="pricing-link"><a className="btn btn-secondary" href="javascript:void(0)" onClick={() => { this.props.history.push('/contact');}}>Reach us to know more</a></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="works wrap mcb-wrap one  valign-top clearfix" >
               <div className="mcb-wrap-inner">
                  <div className="column mcb-column one column_helper ">
                     <div className="helper">
                        <div className="helper_header">
                           <div className="row-title">How it Works</div>
                        </div>
                     </div>
                  </div>
                  <div className="column mcb-column one-fourth column_how_it_works ">
                     <div className="how_it_works  has_border">
                        <div className="image floating" style={{animationDelay: "0s"}}><img src={require('../../images/how-it-works/submit.svg')} />
                          <div className="scale-with-grid" alt="home_betheme_howitworks_1" width="98" height="91"><span className="number">1</span>  </div>
                        </div>
                        <h6 className="bold pt-3 pb-3">Download</h6>
                        <div className="desc">Get Started for free!</div>
                     </div>
                  </div>
                  <div className="column mcb-column one-fourth column_how_it_works ">
                     <div className="how_it_works  has_border">
                        <div className="image floating" style={{animationDelay: ".75s"}}>
                        <figure className="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                          <g fill="none" fill-rule="evenodd">
                            <g>
                              <rect width="44" height="5" x="2" y="40" fill="#FDD760" rx="1"></rect>
                              <path fill="#E5A03E" d="M6 38.5c0-.83.67-1.5 1.5-1.5h33c.83 0 1.5.67 1.5 1.5V40H6v-1.5z"></path>
                              <path fill="#FDD760" d="M38 21a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1v1h-6v-1a1 1 0 0 0 1-1V22a1 1 0 0 0-1-1v-1h6v1zm-11 0a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1v1h-6v-1a1 1 0 0 0 1-1V22a1 1 0 0 0-1-1v-1h6v1zm-11 0a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1v1h-6v-1a1 1 0 0 0 1-1V22a1 1 0 0 0-1-1v-1h6v1z"></path>
                              <path fill="#E5A03E" d="M6 15h36v1.5c0 .83-.67 1.5-1.5 1.5h-33A1.5 1.5 0 0 1 6 16.5V15z"></path>
                              <path fill="#FDD760" d="M22.22 3.9a4.5 4.5 0 0 1 3.56 0l19.54 9.76C46.8 14.4 46.67 15 45 15H3c-1.66 0-1.8-.6-.32-1.34l19.54-9.77z"></path>
                            </g>
                          </g>
                          </svg>
                          </figure>
                          <div className="scale-with-grid" alt="home_betheme_howitworks_2" width="98" height="91"><span className="number">2</span></div> </div>
                        <h6 className="bold pt-3 pb-3">Create Virtual Organization</h6>
                        <div className="desc">Create virtual organization by replicating your organization structure on our platform for effective management.</div>
                     </div>
                  </div>
                  <div className="column mcb-column one-fourth column_how_it_works ">
                     <div className="how_it_works  has_border">
                        <div className="image floating" style={{animationDelay: "1.5s"}} >
                          <figure className="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M43 6H5C3.3 6 2 7.3 2 9v28c0 1.7 1.3 3 3 3h13.4l1.7-3H9c0-2.2-1.8-4-4-4V13c2.2 0 4-1.8 4-4h30c0 2.2 1.8 4 4 4v20c-2.2 0-4 1.8-4 4h-1.2l1.7 3H43c1.7 0 3-1.3 3-3V9c0-1.7-1.3-3-3-3zm-8 9H13c-1.1 0-2 .9-2 2s.9 2 2 2h22c1.1 0 2-.9 2-2s-.9-2-2-2zm-6 14c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z" fill="#FDD760"></path><path d="M38.2 41.9l-4-5.4c1.1-1.2 1.7-2.8 1.7-4.6 0-3.9-3.1-7-7-7s-7 3.1-7 7c0 1.8.7 3.4 1.7 4.6l-4 5.4c-.2.2 0 .6.3.5l3.6-1c.2 0 .4.1.4.2l1 3.7c.1.3.5.3.6 0l2.7-6.4h1.2l2.7 6.4c.1.3.5.3.6 0l1-3.7c0-.2.2-.3.4-.2l3.6 1c.5.1.7-.2.5-.5zM24 32c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" fill="#E5A03E"></path></svg></figure>
                          <div className="scale-with-grid" alt="home_betheme_howitworks_3" width="98" height="91"><span className="number">3</span> </div>
                        </div>
                          <h6 className="bold pt-3 pb-3">Get Approved by UCLID</h6>
                          <div className="desc">Your virtual organization will be verified within 48 hours by our team. Do expect us to reach you over call.</div>
                      </div>
                  </div>
                  <div className="column mcb-column one-fourth column_how_it_works ">
                     <div className="how_it_works has_border">
                        <div className="image floating" style={{animationDelay: "2.25s"}} ><img src={require('../../images/how-it-works/freemium.svg')} />
                        <div className="scale-with-grid" alt="home_betheme_howitworks_4" width="98" height="91"><span className="number">4</span> </div> </div>
                          <h6 className="bold pt-3 pb-3">Start using freemium account</h6>
                        <div className="desc">Start using modules mentioned in above freemium column for free!</div>
                     </div>
                  </div>
                  <div className="column mcb-column one-fourth column_how_it_works ">
                     <div className="how_it_works no_border">
                        <div className="image floating" style={{animationDelay: "3s"}} >
                        <div className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                            <g fill="none" fill-rule="evenodd" transform="translate(0 2)">
                              <path fill="#E5A03E" d="M23.3687586,30.1425368 C20.9219925,32.1886371 17.7706231,33.4201682 14.3316134,33.4201682 C11.6954209,33.4201682 9.22824798,32.6965111 7.11790903,31.4370107 L0.720671335,33.5694159 C0.184840538,33.7480255 -0.0887912638,33.4605854 0.0863663199,32.9351109 L2.21876868,26.5378816 C0.959266296,24.4275489 0.235608095,21.9603837 0.235608095,19.3241995 C0.235608095,11.8043462 6.12405444,5.6598097 13.5415258,5.25 C11.8174159,7.83464563 10.8124059,10.939934 10.8124059,14.279955 C10.8124059,21.9925795 16.1713145,28.4535638 23.3687608,30.1425372 Z"></path>
                              <path fill="#FDD760" d="M39.33385,21.3096508 C40.5933524,19.1993181 41.3170106,16.7321529 41.3170106,14.0959687 C41.3170106,6.31098016 35.0060141,1.77635684e-14 27.2210053,1.77635684e-14 C19.4359965,1.77635684e-14 13.125,6.31098016 13.125,14.0959687 C13.125,21.8809573 19.4359965,28.1919374 27.2210053,28.1919374 C29.8571978,28.1919374 32.3243707,27.4682803 34.4347097,26.2087798 L40.8319474,28.341185 C41.3677782,28.5197947 41.64141,28.2323546 41.4662524,27.70688 L39.33385,21.3096508 Z"></path>
                              <ellipse cx="22.313" cy="16.188" fill="#E5A03E" rx="1.313" ry="1.313"></ellipse>
                              <ellipse cx="27.125" cy="16.188" fill="#E5A03E" rx="1.313" ry="1.313"></ellipse>
                              <ellipse cx="31.938" cy="16.188" fill="#E5A03E" rx="1.313" ry="1.313"></ellipse>
                            </g>
                          </svg>
                        </div>
                        <div className="scale-with-grid" alt="home_betheme_howitworks_4" width="98" height="91"><span className="number">5</span> </div> </div>
                          <h6 className="bold pt-3 pb-3">Reach us to get uclid premium </h6>
                        <div className="desc">To use modules in above premium column reach us now!</div>
                     </div>
                  </div>
               </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pricing;
