import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Switch,
 } from 'react-router-dom';

import './App.css';
import './Style.css';
import './help.css';
import Routes from './Routes';

import PrivacyCenter from './Components/PrivacyCenter';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsofService from './Components/TermsofService';
import TeachersDay from './Components/Blog/BlogPages/teachersday';
import Technology from './Components/Blog/BlogPages/technology';
import Pricing from './Components/Pricing';
import Contact from './Components/Contact';
import Careers from './Components/Careers';
import Modules from './Components/Modules';
import Partner from './Components/Partner';
import Blog from './Components/Blog';
import About from './Components/About';
import Home from './Components/Home';
import NotFound from './Components/NotFound';

import Footer from './Routes/Footer';

class App extends Component {
  render() {
    return (
          <div>
              <Router>
                <Switch>
                  <Routes path={'/center'} component={PrivacyCenter} home={true} />
                  <Routes path={'/privacy'} component={PrivacyPolicy} home={true} />
                  <Routes path={'/terms'} component={TermsofService} home={true} />
                  <Routes path={'/technology-for-teachers'} component={TeachersDay} home={true} />
                  <Routes path={'/technology'} component={Technology} home={true} />
                  <Routes path={'/pricing'} component={Pricing} home={true} />
                  <Routes path={'/contact'} component={Contact} home={true} />
                  <Routes path={"/careers"} component={Careers} home={true} />
                  <Routes path={"/modules"} component={Modules} home={true} />
                  <Routes path={'/blog'} component={Blog} home={true} />
                  <Routes path={'/partner'} component={Partner} home={true} />
                  <Routes path={'/about'} component={About} home={true} />
                  <Routes exact path={'/home'} component={Home} home={true} />
                  <Routes exact path={'/'} component={Home} home={true} />
                  <Routes exact path="*" component={NotFound} home={true} />
                </Switch>
              </Router>
          </div>

    );
  }
}

export default App;
