import React, { Component } from 'react';
import { Container, Row, Col,Card, CardBody, CardImg,} from 'reactstrap';


class TermsofService extends Component {
  render() {
  document.title = 'Terms of Service';
    return (
      <div>
      <div className="section-shaped section-lg">
      <div className="noise-break short">
        <div className="container col-lg-12">
          <div className="text-white">
            <h1 className="title"> Terms of Service </h1>
          </div>
          <div className="title text-white">
            <i className="text-white fa fa-calendar" aria-hidden="true"></i>
            <span className="effective-date"> Effective Date: &nbsp; December 31st, 2018 </span>
          </div>
        </div>
      </div>
      <div className="article-breadcrumbs-container">
        <div className="container">
          <ul className="breadcrumbs">
            <li>
              <a href="http://support.uclid.com/index.htm">
                <i className="fa fa-home" aria-hidden="true"></i>
              </a>
            </li>
            <li title="Uclid Help Desk">
              <a href="http://support.uclid.com/index.htm">Uclid Help Desk</a>
            </li>
            <li title="Terms of Service">
              Terms of Service
            </li>

          </ul>
        </div>
      </div>
      <div className="container ">

      <div className="article-body">
      <div class="category-page">
          <div class="loc-banner-container"></div>
          <div class="category-container">
              <div class="category-trees policies">
                  <div class="section-tree-with-article">
                  <ul>
                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="contract" title="Contract">Contract</h2>
                          <ul>
                          You agree that by clicking “Sign Up” and registering, accessing or using our services, you are agreeing to enter into a contract with UCLID and to be bound by these terms of service. <br/>If you do not agree to this terms of service (“Contract” or “User Agreement”), do not click <b> “Create Free Account” </b>  and do not access or otherwise use any of our Services.
                          By accessing the UCLID App, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. <br/>If you do not agree with any of these terms, you are prohibited from using or accessing this App. The materials contained in this App are protected by applicable copyright and trademark law. Your use of our Services is also subject to our Privacy Policy.
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="license" title="License">License</h2>
                          <ul>
                            These terms of service grant to you a personal, revocable, limited, non-exclusive, and non-transferable license to use the UCLID Service conditioned upon your continued compliance with these terms of service. We reserve all rights not granted in the terms of service.
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="eligibility" title="Eligibility">Eligibility</h2>
                          <ul>
                              The App/services are not for use by anyone under the age of 13. To use the App/services, you agree that:
                              <li> you must be the “Minimum Age” or older; </li>
                              <li> you will only have one UCLID account which must be in your real name; and </li>
                              <li> you are not already restricted by UCLID from using the Services.</li>
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="visibility-and-sharing-of-content" title="Visibility and Sharing of Content">Visibility and Sharing of Content</h2>
                          <ul>
                              As an organization admin, you can upload student information onto our network. Student information includes their full names, DOB, identification marks, residential address, parent name, contact information, transfer certificates, scholarship certificates and other allied information. <br/>Such information shall be safely stored by us and maintained as records. <br/> Such information shall not be visible to other users or to public at large, except for parents of the student who will be granted full access to all information accessible and visible to students. <br/> Visibility of student information to parent is a default setting and the parent who is linked to the student’s profile after verification shall get full access to the student accessed information.
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="rap" title="rap">RAP (Roles Access Permissions)</h2>
                          <ul>
                              For secure usage and functionality optimization, UCLID provides extensive RAP settings where the Admin of an organization is at liberty to grant and deny access to the information being shared on behalf of the organization. Information related to students like curriculum, timetable, lesson planner grades and examinations will have permissive access for teacher, parent and student roles who will each have selective access to the shared information. RAP settings will also determine the viewing privacy of every single information and content that is posted on UCLID.
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="usage" title="Usage">Usage</h2>

                          UCLID App and service is free to use. Premium modules which require customizations by organizations shall be would require payment. Your use of our Website, Application and Services is for your personal and commercial use. Any use of the Application, Website, Services or their contents other than for such purposes is prohibited. Your personal and commercial use of this Application, Website and / or our Services shall be subjected to the following restrictions:
                          <br/>
                              <ul>
                                  <li>You may not decompile, reverse engineer, or disassemble the contents of the Application and / or our Website and/or Services or modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information obtained from the Application and / or our Website and/or Services or remove any copyright, trademark registration, or other proprietary notices from the contents of the Application and / or and / or our Website and/or Services. </li> <br/>

                                  <li>You will not
                                      <ul>
                                          <li>use this Application and / or our Website and/or any of our Service/s for illegal purposes of any kind, or</li>
                                          <li>sell the Application, Services or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any  of our public forum for unlawful purposes of any kind, or</li>
                                          <li>use the Application and / or Website and / or Services in any way that is unlawful, or harms the Company or any other person or entity as determined by the Company.</li>
                                      </ul>
                                  </li> <br/>

                                  <li> No User shall be permitted to perform any of the following prohibited activities while availing our Services:<br/>
                                      <ul>
                                          <li>Making available any content that is misleading, unlawful, harmful, threatening, abusive, tortious, defamatory, libellous, vulgar, obscene, child-pornographic, lewd, lascivious, profane, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;</li>
                                          <li>Stalking, intimidating and/or harassing another and/or inciting other to commit violence;</li>
                                          <li>Transmitting material that encourages anyone to commit a criminal offence, that results in civil liability or otherwise breaches any relevant laws, regulations or code of practice;</li>
                                          <li>Interfering with any other person's use or enjoyment of the Application/Website/Services;</li>
                                          <li>Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner, committing any act that amounts to the infringement of intellectual property or making available any material that infringes any intellectual property rights or other proprietary rights of anyone else;</li>
                                          <li>Make available any content or material that You do not have a right to make available under any law or contractual or fiduciary relationship, unless You own or control the rights thereto or have received all necessary consents for such use of the content;</li>
                                          <li>Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;</li>
                                          <li>Post, transmit or make available any material that contains viruses, trojan horses, worms, spyware, time bombs, cancelbots, or other computer programming routines, code, files or such other programs that may harm the Application/services, interests or rights of other users or limit the functionality of any computer software, hardware or telecommunications, or that may harvest or collect any data or personal information about other Users without their consent;</li>
                                          <li>Access or use the Application/Website/Services in any manner that could damage, disable, overburden or impair any of the Application’s/Website’s servers or the networks connected to any of the servers on which the Application/Website is hosted;</li>
                                          <li>Intentionally or unintentionally interfere with or disrupt the services or violate any applicable laws related to the access to or use of the Application/Website/Services, violate any requirements, procedures, policies or regulations of networks connected to the Application/Website/Services, or engage in any activity prohibited by these Terms;</li>
                                          <li>Disrupt or interfere with the security of, or otherwise cause harm to, the Application/Website/Services, materials, systems resources, or gain unauthorized access to user accounts, passwords, servers or networks connected to or accessible through the Application/Website/Services or any affiliated or linked sites;</li>
                                          <li>Interfere with, or inhibit any user from using and enjoying access to the Application/Website/ Services, or other affiliated sites, or engage in disruptive attacks such as denial of service attack on the Application/Website/Services;</li>
                                          <li>Use deep-links, page-scrape, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to increase traffic to the Application/Website/Services, to access, acquire, copy or monitor any portion of the Application /Website/Services, or in any way reproduce or circumvent the navigational structure or presentation of the Application, or any content, to obtain or attempt to obtain any content, documents or information through any means not specifically made available through the Application/ Website/Services;</li>
                                          <li>Alter or modify any part of the Services;</li>
                                          <li>Use the Services for purposes that are not permitted by: <br/> (i) these Terms; and <br/> (ii) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdiction; or</li>
                                          <li>Violate any of the terms specified under the Terms for the use of the Application /Website/Services.</li>
                                      </ul>
                                  </li> <br/>

                                  <li>As a UCLID user you agree to the following:
                                      <ul>
                                          <li>Use your real name while creating your account;</li>
                                          <li>Provide your correct date of birth;</li>
                                          <li>Provide us accurate information and keep it updated;</li>
                                          <li>Use the Services in a professional manner.</li>
                                          <li>Comply with all applicable laws, including, without limitation, data privacy laws, intellectual property laws and regulatory requirements.</li>
                                          <li>Keep your passwords and all other login information confidential;</li>
                                          <li>Monitor and control all activity that is conducted through your account in connection with the Services;</li>
                                          <li>Promptly notify us if you become aware of or reasonably suspect any illegal or unauthorized activity or a security breach involving your accounts or organization profile, including any loss, theft, or unauthorized disclosure or use of a username, password, or account.</li>
                                          <li>By submitting content on or through our Services, you grant us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute your content in any and all media or distribution methods. You agree that others may use your content in the same way as any other content available through the Services. Other users of the Services may fork, tweak and repurpose your content in accordance with these Terms of service. If you delete your user account your content and name may remain available through the Services.</li>
                                      </ul>
                                  </li>
                              </ul>
                      </li>
                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="contacting-users" title="Contacting Users">Contacting Users</h2>
                          <ul>We may, based on any form of access to UCLID Network and App (including free download/trials) or Services or Website or registrations through any source whatsoever, contact the User through sms, email and call, to give information about our services as well as notifications on various important updates and/or to seek permission for introduction of our services. The User expressly grants such permission to contact him/her through telephone, SMS, e-mail and holds UCLID indemnified against any liabilities including financial penalties, damages, expenses in case the User’s mobile number is registered with Do not Call (DNC) database. By registering yourself, you agree to make your contact details available to our employees, associates and partners so that you may be contacted for education information and promotions through telephone, SMS, email etc.</ul> <br/>
                          <ul>Upon registration through any means whatsoever, we may contact user through the registered mobile number or e-mail provided by user to enable effective provision of Services. The User expressly permits us to contact him/her while utilising the Services, through the above mentioned means at any time post registration. Further, we shall have the right to monitor the download and usage of the Application/Services and the contents thereof by the User, to analyse such usage and discuss the same with the User to enable effective and efficient usage of the Services. </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="disclaimer-and-warranties" title="Disclaimer and Warranties">Disclaimer and Warranties</h2>
                          <ul>
                              The materials on UCLID's App, network and allied services are provided on an 'as is' basis. UCLID makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or warranties of fitness for a particular purpose, or warranties of non-infringement of intellectual property or other violation of rights.<br/>  Further, UCLID does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its App or otherwise relating to such materials or on any app or sites linked to this App.<br/>
                              UCLID uses open source software for development of its product but does not edit, modify, recreate or reengineer the existing open source wares. UCLID is under no obligation whatsoever to make available publicly the codes, programs and content originally conceived by UCLID.<br/>
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="limitations" title="Limitations">Limitations</h2>
                          <ul>
                               In no event shall UCLID be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on UCLID's website/App, even if UCLID or a UCLID authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.<br/> <br/> UCLID has the exclusive right to limit your use of the Services, including the number of people you connect with and your ability to contact other users. We also reserve the right to restrict, suspend, or terminate your account if we believe that you may be in breach of this Contract or law or are misusing the Services by violating any terms of service.
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="links" title="Links">Links</h2>
                          <ul>
                          UCLID has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by UCLID of the site. Use of any such linked website is at the user's own risk.
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="waiver" title="Waiver">Waiver</h2>
                          <ul>
                          No failure or delay by user in exercising any right under the Terms of Service will constitute a waiver of that right. No waiver under the Terms of Service will be effective unless made in writing and signed by an authorized representative of the user being deemed to have granted the waiver.
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="severability" title="Severability">Severability</h2>
                          <ul>
                              The Terms of Service will be enforced to the fullest extent permitted under applicable law. If any provision of the Terms of Service is held by a court of competent jurisdiction to be contrary to law, the provision will be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of the Terms of Service will remain in effect.

                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="assignment" title="Assignment">Assignment</h2>
                          <ul>
                              You may not assign any of your rights or delegate your obligations under these Terms of Service, whether by operation of law or otherwise, without the prior written consent of us (not to be unreasonably withheld). We may assign these Terms of Service in their entirety (including all terms and conditions incorporated herein by reference), without your consent, to a corporate affiliate or in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of our assets.
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="modifications" title="Modifications">Modifications</h2>
                          <ul>
                              We may revise these Terms of service at any time to improve our services, develop new features and increase user satisfaction. By using UCLID app/services you are agreeing to be bound by the then current version of these Terms of service. We will provide you with reasonable notice prior to any change taking effect either by emailing the email address associated with your account or by messaging you through the Services. You can review the revised version of these Terms of service at any time by visiting this page. Any material revisions to these Terms of service will become effective on the date set forth in our notice, and all other changes will become effective on the date we publish the change. If you use the app/services after the effective date of any changes, that use will constitute your acceptance of the changes and revision of these Terms of service.
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="intellectual-property-rights" title="Intellectual Property Rights">Intellectual Property Rights</h2>
                          <ul>
                              UCLID reserves all of its intellectual property rights in the application, website and Services. Using our Services does not give you any ownership in our Services or the content or information made available through our Services. Trademarks and logos used in connection with the Services are to be the trademarks of their respective owners. UCLID logo and trademark, graphics used for our Services are trademarks or registered trademarks of UCLID. You can only use our copyrights or trademarks (or any similar marks) as expressly permitted by these Terms of Service and privacy policy or with our prior written permission. You must obtain our prior written to modify, create derivative works of, decompile or otherwise attempt to extract source code from us.
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="termination" title="Termination">Termination</h2>
                          <ul>
                              This agreement maybe terminated either by you or UCLID at any time. We also reserve the right to suspend or end the Service at any time at our discretion and without notice. We may suspend or terminate your use of the Service and remove your Content if you’re not complying with these terms of service, or using the Service in a manner that may cause us legal liability, disrupt the Service, disrupt others’ use of the Service or, in our sole opinion, reason, cause harm.  Users are informed that upon termination of this contract, the right to use and access our App & service will cease immediately, and that upon termination of an account, all of the content posted to the account will automatically be removed.<br/> <br/> The following shall survive termination:<br/>
                              <li> Our rights to use and disclose your feedback; </li>
                              <li> Users’ and/or Visitors’ rights to further re-share content and information you shared through the Service to the extent copied or re-shared prior to termination;</li>
                              <li> Sections 8, 9, 11, 15, 16 and 18 of this agreement.</li>
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="service-messages" title="Service Messages">Service Messages</h2>
                          <ul>
                              By agreeing to this terms of service you acknowledge that we will provide service messages to you in the following ways:<br/>
                              <li>a message within the Service, or </li>
                              <li> a message sent to the contact information you provided us (e.g., email and mobile number). You agree to keep your contact information up to date.</li>
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="governing-laws" title="Governing Law; Venue; Fees">Governing Law; Venue; Fees</h2>
                          <ul>
                              The Terms of Service and any disputes arising out of or related hereto, will be governed exclusively by the same applicable governing law of the Privacy Policy and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location. The courts located in the applicable venue of the dispute will have exclusive jurisdiction to adjudicate any dispute arising out of or relating to the Terms of Service or its formation, interpretation or enforcement. Each user hereby consents and submits to the exclusive jurisdiction of such courts. Every user also hereby waives any right to jury trial in connection with any action or litigation in any way arising out of or related to the Terms of Service. In any action or proceeding to enforce rights under the Terms of Service, the prevailing party will be entitled to recover its reasonable costs and advocate’s fees.
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="notices" title="Notices">Notices</h2>
                          <ul>
                          If you want to send us notices or service of process, please contact us by mail:  <a href="mailto: support@uclid.com"> <b> support@uclid.com </b> </a> <br/>
                          </ul>
                      </li>

                      <li class="section"><a class="zd_anchor" name="UCLID TERMS OF SERVICE"></a>
                          <h2 id="reporting-content" title="Governing Law; Venue; Fees">Reporting Content</h2>
                          <ul>
                          If you come across unscrupulous activities, impersonation, underaged users or any other such related activities which are prohibited under our terms of service: and if such activities cause you inconvience, harm<br/> please contact us by mail: <a href="mailto: support@uclid.com"> <b> support@uclid.com </b> </a> <br/>
                          </ul>
                      </li>
                  </ul>
                  </div>
              </div>
          </div>
      </div>

      </div>



  </div>
    </div>


      </div>
    );
  }
}

export default TermsofService;
