import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class About extends Component {
  render() {

    return (

      <div className="">
        <Helmet>
           <meta charSet="utf-8" />
           <title>About</title>
           <meta name="description" content="UCLID aims to power educational organizations with tools that help students improve their learning and find more opportunities throughout their academic journey."/>
           <meta name="keywords" content="advent of lms, progression of lms, boom of learning technologies, inception of uclid, improve opportunities, multiple organizations connect"/>
           <link rel="canonical" href="https://www.uclid.com/about" />
        </Helmet>
        <div className="pt-7 pb-7">
          <div className="uclid-stripes-1">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
          </div>

          <div className="about container pt-7 ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 image-center">
              <div className="row pt-lg-lg">
                <h2 className="about-title pb-4">Connect, Learn, Improve & Build your Portfolio!</h2>
                <p>UCLID aims to power educational organizations with tools that help students improve their learning and <br/> find more opportunities throughout their academic journey.</p>
                <img className="team" src={require('../../images/svg/team.svg')}/>
              </div>
            </div>
          </div>

          {/* <!-- TimeLine--> */}
          <section className="edu-timeline">
            <div className="container">
              <div className="edu-timeline-block">
                <div className="header">
                  <h2 className="title-sm pb-3">The Timeline</h2>
                  <h4 className="title-mn pb-3">We are part of Innovation.</h4>
                  <p className="pb-5">The Learning Curves of Education</p>
                </div>
                <div className="spacer-medium"></div>
                {/* <!-- Mile stone of LMS --> */}
                <section id="hiw" className="hiw p-90-60">
                  <div className="line hidden-xs"></div>
                  <div className="container">
                    <div className="row">
                      {/* <!-- Item - 1--> */}
                      <div className="col-6 col-sm-6 col-md-3 hiw-item mb-30">
                        <div className="bg-color-2 puls mb-30 floating" style={{animationDelay: "0s"}}>1997</div>
                        <p className="bold">ADVENT OF LMS </p>
                        <p className="light thin">Introduction to virtual learning and course management systems.</p>
                      </div>
                        {/* <!-- Item - 2--> */}
                      <div className="col-6 col-sm-6 col-md-3 hiw-item mb-30">
                        <div className="bg-color-2 puls mb-30 floating" style={{animationDelay: ".75s"}}>2002</div>
                        <p className="bold">Progression OF LMS </p>
                        <p className="light thin">Free and open source learning management system with K-12 was the target market. </p>
                      </div>
                        {/* <!-- Item - 3--> */}
                      <div className="col-6 col-sm-6 col-md-3 hiw-item mb-30">
                        <div className="bg-color-2 puls mb-30 floating" style={{animationDelay: "1.5s"}}>2012</div>
                        <p className="bold">Boom of Learning Technologies</p>
                        <p className="light thin">linking of social & learning platforms, AI based learning.</p>
                      </div>
                        {/* <!-- Item - 4--> */}
                      <div className="col-6 col-sm-6 col-md-3 hiw-item mb-30">
                        <div className="bg-color-2 puls mb-30 floating" style={{animationDelay: "2.25s"}}>2018</div>
                        <p className="bold">Inception of UCLID</p>
                        <p className="light thin">Unified Education Network</p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
          {/* <!-- End edu TimeLine --> */}


          <div className="mission pt-6 pb-6">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <img className="mission-image" src={require('../../images/svg/mission.svg')} />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h2 className="title">Mission</h2>
                <h5 className="sub-title pt-3 pb-3">A thing of utility is joy forever!</h5>
                <div className="spacer-small"></div>
                <p>An organized academic cycle lasts anywhere between 16-25 years. Students go through various phases of acquiring knowledge and skills, join many educational organizations, compete in the many different qualifying tests to finally seek new jobs for their survival.
                  <br/> <br/> Learning predominantly occupies a larger part of our lives than we imagine. We should organize it to our best and we must learn, improve, test and connect with right people in best ways possible for progress.
                  <br/> <br/> UCLID does just that to learners - organize learning and improve opportunities.
                </p>
              </div>
            </div>
          </div>


          <div className="about-uclid container">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <h3 className="about-uclid-title pb-3">What is UCLID?</h3>
                <div className="">
                  <p>With extensive Learning Management System combined with multi organization connect-
                    <ul>
                      <li> Students can be associated to multiple organizations at a time to improve their learning & build a virtual portfolio which will enable their progression towards a guided career path. </li>
                      <li>  Organizations can maintain a public profile and administrative base for their daily operations and can attract new learners through our network.</li>
                    </ul>
                    <p> As a complete education life cycle management platform, our learning, teaching & managing modules will provide you with an instant solution for your essential academic needs. </p>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <h2 className="row-title">In the news</h2>
            <div id="our-story" className="newspostcardoverlay blockimagewrapper">
              <a href="https://inc42.com/buzz/uclid-hyderabad-edtech-funding/" target="_blank">
                <div className="newspostcardauthor hidden-md hidden-sm hidden-xs">October 12, 2017</div>
                <h1 className="newspostcardname hidden-md hidden-sm hidden-xs">INC42:</h1>
                <div className="newspostcardline hidden-md hidden-sm hidden-xs"></div>
                <div className="newspostcardsummary hidden-md hidden-sm hidden-xs">Hyderabad Based Edtech Startup UCLID Raises Seed Funding From US-Based Investors</div>
              </a>
            </div>
            <div className="spacer-medium"></div>
          </div>


        </div>
      </div>
    );
  }
}

export default About;
