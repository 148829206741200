import React, { Component } from 'react';
import classnames from "classnames";
import TwentyTwenty from 'react-twentytwenty';
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
  Button
} from "reactstrap";

import {Helmet} from "react-helmet";

class Modules extends Component {
  state = {
    iconTabs: 1,
    plainTabs: 1
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  render() {
    return (
      <div className="">
        <Helmet>
           <meta charSet="utf-8" />
           <title>Product</title>
           <meta name="description" content="UCLID aims to bring true power of technology to educational organizations with tools that help students improve their learning and organization leaders, teachers to deliver education to their best.  A learning management system, focused communication network & progress management platform, Uclid is the right combination of essential tools you require for attaining excellence in your organization."/>
           <meta name="keywords" content="Hiring, full stack developer, front end developers, customer relationship managers, business development executives. "/>
           <link rel="canonical" href="https://www.uclid.com/careers" />
        </Helmet>
        <div className=" pt-7 pb-7 ">

          {/*<div class="careers">
            <div class="career s1"></div>
            <div class="career s2"></div>
            <div class="career s3"></div>
          </div>*/}

          <div className="uclid-stripes-1">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
          </div>

          <div className="modules-content container">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 image-center">
              <div className="row pt-lg-lg ">
                {/*<h2 className="modules-content-title pb-4">Manage your Organizations on your mobile</h2>*/}
                <h2 className="modules-content-title pb-4">Powering organizations with technology</h2>
                <p>UCLID aims to bring true power of technology to educational organizations across the globe <br/> with tools that help students improve their learning and organization leaders, teachers to deliver education to their best. </p>
              </div>
            </div>
          </div>


          <section id="product" className="section">
            <div className="container">
              <div className="row row-grid align-items-center pb-5">
                <div className="col-lg-5 col-md-8 order-lg-1 order-md-2 pr-md-5 digital">
                  <img src= {require('../../images/svg/digitalize.svg')} className="img-fluid floating" />
                </div>
                <div className="col-lg-7 col-md-12 order-lg-2 order-md-1 mt-5 ">
                  <div className="">
                    <h3 className="text-primary mb-4">Digitalize Academics</h3>
                    <p>Digitalize your day to day academic activities with our free to use modules. Save time and effort in storing your data and accessing it effectively when ever required. Every module mentioned below will help teachers and students in making their classroom environment a lot more interesting.</p>

                  </div>
                  <figure className="col-lg-12 product-modules">

                    <div className="row pb-5">
                      <div className="col-md-4 col-xs-6" align="center">
                        <button color="default" id="lesson-plan" >
                          <img src={require('../../images/modules/lesson-plan.svg')} />
                          <h5 class="module-name" class="module-name">Lesson Plan</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="lesson-plan"
                          >
                            <PopoverHeader>Lesson Plan</PopoverHeader>
                            <PopoverBody>
                            Lesson plans can be created by admins or teachers and can integrate it with time table, assignments, attendance  and tests. Deep insight into course management and course progress with details of various activities conducted can be viewed chapter wise in the lesson plans by admins, teachers, students & parents.

                            </PopoverBody>
                          </UncontrolledPopover>
                      </div>

                      <div className="col-md-4 col-xs-6" align="center">
                        <button color="default" id="time-table" >
                          <img src={require('../../images/modules/time-table.svg')} />
                          <h5 class="module-name">Time Table</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="time-table"
                          >
                            <PopoverHeader>Time Table</PopoverHeader>
                            <PopoverBody>
                            Keep everyone around the classroom updated with effective time table setup. Time table creation on Uclid is very flexible and dynamic, making it very easy to use.

                            </PopoverBody>
                          </UncontrolledPopover>
                      </div>

                      <div className="col-md-4 col-xs-6" align="center">
                        <button color="default" id="attendance" >
                          <img src={require('../../images/modules/attendance.svg')} />
                          <h5  class="module-name">Attendance</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="attendance"
                          >
                            <PopoverHeader>Attendance</PopoverHeader>
                            <PopoverBody>
                            Mark daily attendance from phone, tablet or web and reduce additional stress and redundancy. Easy to use interphase helps teacher to complete the process quickly and the information can be instantly shared with parents within the app.

                            </PopoverBody>
                          </UncontrolledPopover>
                      </div>
                    </div>

                    <div className="row pb-5">
                    <div className="col-md-4 col-xs-6" align="center">
                        <button color="default" id="diary" >
                          <img src={require('../../images/modules/diary.svg')} />
                          <h5  class="module-name">Diary</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="diary"
                          >
                            <PopoverHeader>Diary</PopoverHeader>
                            {/* <PopoverBody>
                            Store, access & share documents, sheets, multimedia files, photos and notes with students and peers through Uclid app. Download, edit, and delete
                            any uploaded files, as well as upload new ones, either directly from your computer or mobile. You can control the sharing of any uploaded file with visibility settings
                            for each post which will give you much more freedom to share with your discretion.
                            </PopoverBody> */}
                          </UncontrolledPopover>
                      </div>
                      <div className="col-md-4 col-xs-6" align="center">
                        <button color="default" id="feedback" >
                          <img src={require('../../images/modules/feedback.svg')} />
                          <h5  class="module-name">Feedback</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="feedback"
                          >
                            <PopoverHeader>Feedback</PopoverHeader>
                            <PopoverBody>
                            Performance management starts with feedback. Uclid helps in taking timely, quick and effective feedback of all matters in an organization including classroom activities, college administration, staff & student performance. This modules helps Teachers & Organization leaders focus on areas of improvement.
                            </PopoverBody>
                          </UncontrolledPopover>
                      </div>

                      <div className="col-md-4 col-xs-6" align="center">
                        <button color="default" id="assignment" >
                          <img src={require('../../images/modules/assignment.svg')} />
                          <h5 class="module-name">Assignment</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="assignment"
                          >
                            <PopoverHeader>Assignment</PopoverHeader>
                            <PopoverBody>
                            Allows teachers to give assignments, track and evaluate them effectively all from the App, students can submit assignments within the app and also can view the marks post evaluation.


                            </PopoverBody>
                          </UncontrolledPopover>
                      </div>

                      
                    </div>

                    <div className="row pb-5">
                      <div className="col-md-4 col-xs-6" align="center">
                          <button color="default" id="file-sharing" >
                            <img src={require('../../images/modules/file-sharing.svg')} />
                            <h5  class="module-name">File Sharing</h5>
                          </button>
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target="file-sharing"
                            >
                              <PopoverHeader>File Sharing</PopoverHeader>
                              <PopoverBody>
                              Store, access & share documents, sheets, multimedia files, photos and notes with students and peers through Uclid app. Download, edit, and delete
                              any uploaded files, as well as upload new ones, either directly from your computer or mobile. You can control the sharing of any uploaded file with visibility settings
                              for each post which will give you much more freedom to share with your discretion.
                              </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                      <div className="col-md-4 col-xs-6" align="center">
                        <button color="default" id="admissions" >
                          <img src={require('../../images/modules/admissions.svg')} />
                          <h5  class="module-name">Admissions</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="admissions"
                          >
                            <PopoverHeader>Admissions</PopoverHeader>
                            <PopoverBody>
                            This module allows students to complete admissions online/offline. Admission process on Uclid makes it easy for Organizations to manage student data and quicken the process. The whole admission process can be managed within the app including the documents collection and sharing.
                            </PopoverBody>
                          </UncontrolledPopover>
                      </div>

                      <div className="col-md-4 col-xs-6" align="center">
                        <button color="default" id="payments" >
                          <img src={require('../../images/modules/payments.svg')} />
                          <h5 class="module-name">Fee Payments</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="payments"
                          >
                            <PopoverHeader>Fee Payments</PopoverHeader>
                            <PopoverBody>
                            Collection or payment of fee can be made easier with this module on Uclid. Fee setup, collection and sending timely reminders to parents can all be done with the complete fee management system within the App.
                            </PopoverBody>
                          </UncontrolledPopover>
                      </div>

                    </div>

                  </figure>
                </div>



              </div>


              <div className="row row-grid align-items-center">
                <div className="col-lg-5 col-md-9 order-lg-2 order-md-2 communication" align="center">
                  <img src= {require('../../images/svg/communication.svg')} className="floating" />
                </div>
                <div className="col-lg-7 col-md-12 order-lg-1 order-md-1">
                  <div className="pb-5">
                    <h3 className="text-primary mb-4">Communicate with peers</h3>
                    <p>Communicate effectively on the go! Stay connected and communicate with everyone within organization. Also, keep parents informed of their kid’s academic activities in real time.</p>
                  </div>
                  <figure className="col-lg-12 product-modules">
                    <div className="row pb-5">
                      <div className="col-md-4 col-xs-6" align="center">
                        <button color="default" id="announcement" >
                          <img src={require('../../images/modules/announcement.svg')} />
                          <h5 class="module-name" class="module-name">Announcement</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="announcement"
                          >
                            <PopoverHeader>Announcement</PopoverHeader>
                            <PopoverBody>
                            With our announcements improve your communications at free of cost! With our in App push notifications. Our system will automatically identify students or parents offline and will send SMS for those who are away from the internet or smart phones. SMS could be purchased from Uclid to make best use of this feature.

                            </PopoverBody>
                          </UncontrolledPopover>
                      </div>

                      <div className="col-md-4 col-xs-6" align="center" >
                        <button color="default" id="messaging" >
                          <img src={require('../../images/modules/messaging.svg')} />
                          <h5 class="module-name">Messaging</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="messaging"
                          >
                            <PopoverHeader>Messaging</PopoverHeader>
                            <PopoverBody>
                            Uclid allows you to have academic communications through a dedicated multi dimensional medium with utmost privacy and organization scrutiny to avoid any possible miscommunications between teachers, staff, parents and students.
                            </PopoverBody>
                          </UncontrolledPopover>
                      </div>


                      <div  className="col-md-4 col-xs-6" align="center">
                        <button color="default" id="grievance" >
                          <img src={require('../../images/modules/grievance.svg')} />
                          <h5  class="module-name">Grievance</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="grievance"
                          >
                            <PopoverHeader>Grievance</PopoverHeader>
                            <PopoverBody>
                            Uclid understands the gravity of providing redress to issues as soon as possible. The grievance mechanism at your organization can work better with Uclid which will help expedite grievance redressal and also maintain a record of it for use during further recurrences.
                            </PopoverBody>
                          </UncontrolledPopover>
                      </div>
                    </div>

                    <div className="row pb-5">

                    <div className="col-md-4 col-xs-6"  align="center">
                      <button color="default" id="guardian-connect" >
                        <img src={require('../../images/modules/guardian-connect.svg')} />
                        <h5 class="module-name">Guardian Connect</h5>
                      </button>
                        <UncontrolledPopover
                          trigger="hover"
                          placement="top"
                          target="guardian-connect"
                        >
                          <PopoverHeader>Guardian Connect</PopoverHeader>
                          <PopoverBody>
                          Allows parents to connect to their ward’s profile to keep them updated about their activities instantly. Every update of the student will  be shared with them with in no time and keeps them engaged them throughout their ward’s academic process.

                          </PopoverBody>
                        </UncontrolledPopover>
                    </div>

                      <div  className="col-md-4 col-xs-6" align="center">
                        <button color="default" id="discussion" >
                          <img src={require('../../images/modules/discussion.svg')} />
                          <h5  class="module-name">Discussion</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="discussion"
                          >
                            <PopoverHeader>Discussion</PopoverHeader>
                            <PopoverBody>
                              Discussions on Uclid will always be lively and fun with the many options of using photos, emoticons, polls etc which have been exclusively designed for any and all academic discussions.Anyone within the organization can form groups according to their interests and discuss academics,events, experiments etc. These discussions can be held in closed groups where content privacy can be maintained.
                            </PopoverBody>
                          </UncontrolledPopover>
                      </div>

                      <div className="col-md-4 col-xs-6" align="center">
                        <button color="default" id="groups" >
                          <img src={require('../../images/modules/groups.svg')} />
                          <h5  class="module-name">Groups</h5>
                        </button>
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target="groups"
                          >
                            <PopoverHeader>Groups</PopoverHeader>
                            <PopoverBody>
                            Forming groups is essential for bulk communication. Uclid lets you segregate students, teachers, parents and other stakeholders into groups where channelized communication can be done to a specific set of people.
                            </PopoverBody>
                          </UncontrolledPopover>
                      </div>





                    </div>

                  </figure>
                </div>

              </div>

              <div className="row row-grid align-items-center pb-5">
                <div className="col-lg-5 col-md-8 order-lg-1 order-md-2 pr-md-5 assess-your-progress">
                  <img src= {require('../../images/svg/progress-tracker.svg')} className="img-fluid floating" />
                </div>
                <div className="col-lg-7 col-md-12 order-lg-2 order-md-1 mt-5 ">
                  <div className=" ">
                    <h3 className="text-primary mb-4">Assess the progress</h3>
                    <p>If excellence in education is your goal, Uclid should be on your team. Uclid powers your organization with its Progress tracking, smart & deepened Analytics modules to attain excellence in overall organization performance in academics.</p>
                    <figure className="col-lg-12 product-modules">

                      <div className="row pb-5">
                        <div className="col-md-4 col-xs-6" align="center">
                          <button color="default" id="exam" >
                            <img src={require('../../images/modules/exams.svg')} />
                            <h5 class="module-name" class="module-name">Exams</h5>
                          </button>
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target="exam"
                            >
                              <PopoverHeader>Exams</PopoverHeader>
                              <PopoverBody>
                              Exam module is for both online and offline exams. Online exam allows  Organizations to conduct both objective & subjective exams within the App. While offline exams allows organizations to store answer sheets and assign marks to students for assessment and communication purposes.

                              </PopoverBody>
                            </UncontrolledPopover>
                        </div>

                        <div className="col-md-4 col-xs-6" align="center">
                          <button color="default" id="report-card" >
                            <img src={require('../../images/modules/report-card.svg')} />
                            <h5 class="module-name">Report Card</h5>
                          </button>
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target="report-card"
                            >
                              <PopoverHeader>Report Card</PopoverHeader>
                              <PopoverBody>
                              Progress records are an integral part of assessments. With Uclid's Report Card feature a student's all round performance including curricular and extra
                              curricular activities can be seen. A single report card will contain details of all the programs & courses a student is taking so that a parent can get a comprehensive
                              picture of their child.

                              </PopoverBody>
                            </UncontrolledPopover>
                        </div>

                        <div className="col-md-4 col-xs-6" align="center">
                          <button color="default" id="portfolio" >
                            <img src={require('../../images/modules/portfolio.svg')} />
                            <h5  class="module-name">Portfolio</h5>
                          </button>
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target="portfolio"
                            >
                              <PopoverHeader>Portfolio</PopoverHeader>
                              <PopoverBody>
                              A portfolio on Uclid is a 360 degree outlook on a person’s academic performance, employment history, other skills & experience. Unlike a regular profile on a resume or curriculum vitae Uclid portfolio has the most authentic data with verified accreditations. With this portfolio we will take a user’s professional representation to a whole new level!

                              </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                      </div>

                      <div className="row pb-5">

                        <div className="col-md-4 col-xs-6" align="center">
                          <button color="default" id="analytics" >
                            <img src={require('../../images/modules/analytics.svg')} />
                            <h5 class="module-name">Analytics</h5>
                          </button>
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target="analytics"
                            >
                              <PopoverHeader>Analytics</PopoverHeader>
                              <PopoverBody>
                              Analytics gives deep insights into Organization, students, teachers performance metrics. With smart analytics of Uclid decision making and progress management can be made a lot easier and efficient.

                              </PopoverBody>
                            </UncontrolledPopover>
                        </div>

                        <div className="col-md-4 col-xs-6" align="center">
                          <button color="default" id="certifications" >
                            <img src={require('../../images/modules/certifications.svg')} />
                            <h5  class="module-name">Certifications</h5>
                          </button>
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target="certifications"
                            >
                              <PopoverHeader>Certifications</PopoverHeader>
                              <PopoverBody>
                              With certifications on Uclid organizations can certify and authenticate credentials of students graduating from the organization these details would become a vital part of the student portfolio.

                              </PopoverBody>
                            </UncontrolledPopover>
                        </div>

                        </div>

                    </figure>
                  </div>
                </div>

              </div>
            </div>
          </section>

            <div className="user-roles">
              <h3 class="text-secondary mb-4" align="center">User Roles on UCLID</h3>
                <Col className="mt-5 pt-5 pb-7 mt-lg-0 container" lg="10">
                  <div class="row">
                    <Col lg={{ size: 3}} md={{ size: 3 }} sm={{ size: 12}} className="nav-wrapper">
                      <Nav
                        className="nav-fill flex-column flex-md-column"
                        id="tabs-icons-text"
                        pills
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            aria-selected={this.state.plainTabs === 1}
                            className={classnames("mb-sm-3 mb-md-0", {
                              active: this.state.plainTabs === 1
                            })}
                            onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                            href="#pablo"
                            role="tab"
                          >
                            Admin
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-selected={this.state.plainTabs === 2}
                            className={classnames("mb-sm-3 mb-md-0", {
                              active: this.state.plainTabs === 2
                            })}
                            onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                            href="#pablo"
                            role="tab"
                          >
                            Teacher
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-selected={this.state.plainTabs === 3}
                            className={classnames("mb-sm-3 mb-md-0", {
                              active: this.state.plainTabs === 3
                            })}
                            onClick={e => this.toggleNavs(e, "plainTabs", 3)}
                            href="#pablo"
                            role="tab"
                          >
                            Student
                          </NavLink>
                        </NavItem>
                          <NavItem>
                            <NavLink
                              aria-selected={this.state.plainTabs === 4}
                              className={classnames("mb-sm-3 mb-md-0", {
                                active: this.state.plainTabs === 4
                              })}
                              onClick={e => this.toggleNavs(e, "plainTabs", 4)}
                              href="#pablo"
                              role="tab"
                            >
                              Parent
                            </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>

                  <Col lg={{ size: 8, offset: 1}} md={{ size: 8, offset: 1}} sm={{ size: 12}} >
                    <Card className="shadow">
                      <CardBody>
                        <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
                          <TabPane tabId="plainTabs1">
                            <p className="description">
                              Create your virtual Organization on UCLID to manage everything about students from Admission to Certification.
                              <ul className="description-list">
                                <li>Be it a school, college, or a training institution of any size or domain, create your virtual organization on UCLID.</li>
                                <li>Manage multiple branches of your Organization from one profile</li>
                                <li>Add Admins, Teachers & Students</li>
                                <li>Manage student information, records and payments on the GO.</li>
                                <li>Monitor performances of any classroom or student</li>
                                <li>All these at free of cost!</li>
                              </ul>
                              </p>
                          </TabPane>
                          <TabPane tabId="plainTabs2">
                          <p className="description">
                            Teaching through UCLID is life made easy. Manage your classroom and stay connected to the students and parents through safe communication channels.
                            <ul className="description-list">
                              <li>Manage classroom with modules like Lesson planning, attendance, Assignments, material sharing etc</li>
                              <li>Communicate with students and teachers</li>
                              <li>Receive timely feedbacks</li>
                              <li>Develop interactive Profile</li>
                            </ul>
                            </p>
                          </TabPane>
                          <TabPane tabId="plainTabs3">
                          <p className="description">
                            Stay connected to multiple organizations that you study at through a single APP. Manage your learning and explore more education opportunities.
                            <ul className="description-list">
                              <li>One APP many organizations, manage multiple organizations at a time through UCLID.</li>
                              <li>Stay connected to CLASSROOM to track attendance, assignments, access materials etc</li>
                              <li>Link parent account to share updates</li>
                              <li>Develop portfolio find more opportunities</li>
                            </ul>
                            </p>
                          </TabPane>
                          <TabPane tabId="plainTabs4">
                            <p className="description">
                              Experience your child's classroom real time with our APP. Know more & do more everyday to be a part of your child's growth.
                              <ul className="description-list">
                                <li>Track learning of multiple children through one APP</li>
                                <li>Track multiple organizations of students through same profile</li>
                                <li>Deep analytics of students academic curve</li>
                                <li>Regular connect with parents and admins</li>
                              </ul>
                              </p>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                  </div>
                </Col>
            </div>


            {/* <h3 class="text-secondary pb-5 pt-5" align="center">Before | After</h3>

            <div className="twentytwenty container col-lg-8">
            <TwentyTwenty
              left={<img src={require('../../images/slider/before.svg')}/>}
              right={<img src={require('../../images/slider/after.svg')}/>}
              slider={<div className="slider"/>
              }
            />
            </div>
            <div className="ba_labels pt-5">
              <div className="before-label">Before</div>
              <div className="after-label">After</div>
            </div> */}

        </div>
      </div>
    );
  }
}

export default Modules;
