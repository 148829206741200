import React, { Component } from 'react';
import {
    Route,
} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

class Routes extends Component {
  state = {
    loading: true
  }
  componentWillMount(){
    setTimeout(()=>{
      this.setState({
        loading: false
      })
    }, 100)
  }
  componentWillReceiveProps(){
    this.setState({
      loading: true
    })
    setTimeout(()=>{
      this.setState({
        loading: false
      })
    }, 100)
  }
  render() {
    const {home, component: Component, ...rest} = this.props;
    const {loading} = this.state;
    return (
      <Route {...rest} render= {props => (
        <div>
        {
          loading ?
          <div className="container " align="center">
            <img className="loader" src={require("../images/loader.gif")} />
          </div> :
          <>
              <Header {...props} home={home}  />
                  <Component {...props} />
              <Footer {...props} />
          </>
        }
        </div>
      )}/>
    );
  }
}

export default Routes;
