import React, { Component } from 'react';
import { Link } from "react-router-dom";
import classnames from "classnames";

import {
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

class Partner extends Component {
  render() {
    document.title = 'Partner with us';
    return (
      <div className="pt-7 pb-7">
         <div className="about-stripes">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>

      <div className="about container pt-7 ">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 image-center">
          <div className="row pt-lg-lg">
            <h2 className="about-title pb-4">Connect, Learn, Improve & Build your Portfolio!</h2>
            <p>UCLID aims to power educational organizations with tools that help students improve their learning and <br/> find more opportunities throughout their academic journey.</p>
            <img className="team" src={require('../../images/svg/team.svg')}/>
          </div>
        </div>
      </div>


      <Navbar className="navbar-dark bg-default mt-4" expand="lg">
        <Container>
          <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
            UCLID Partner Programs
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar-primary">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-primary">
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="..."
                      src={require("../../images/favicon.png")}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-primary">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-lg-auto" navbar>
              <NavItem>
                <NavLink href="#pablo" onClick={e => e.preventDefault()}>
                  Student Partner <span className="sr-only">(current)</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#pablo" onClick={e => e.preventDefault()}>
                  Channel Partner
                </NavLink>
              </NavItem>

              <NavItem className="d-none d-lg-block ml-lg-4">
                <Button
                  className="btn btn-primary btn-icon partner-button"
                  color=""
                  href=""
                  target="_blank"
                >
                  <span className="nav-link-inner--text ml-1">
                    Become a partner
                  </span>
                </Button>
              </NavItem>

            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>


      

      </div>

    );
  }
}

export default Partner;
